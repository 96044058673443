import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { article as articleData } from "shared/data/article";

import { getReadingTime } from "scmp-app/lib/utils";
import type { contentReadingTimeContent$key } from "scmp-app/queries/__generated__/contentReadingTimeContent.graphql";

import { Container } from "./styles";

type Props = {
  className?: string;
  reference: contentReadingTimeContent$key;
};

export const ContentReadingTime: FunctionComponent<Props> = ({ className, reference }) => {
  const content = useFragment(
    graphql`
      fragment contentReadingTimeContent on Content {
        readingTime
        flag
      }
    `,
    reference,
  );

  const readingTime = getReadingTime(content?.readingTime ?? 0);

  if (content.flag === articleData.flag.video) return null;

  return (
    <Container className={className}>
      <span>Reading Time:</span>
      <strong>{readingTime}</strong>
    </Container>
  );
};

ContentReadingTime.displayName = "ContentReadingTime";
``;
