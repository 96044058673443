import styled from "@emotion/styled";
import { ShareButton } from "@product/scmp-sdk";

export const Container = styled.div``;

export const StyledShareButton = styled(ShareButton)`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;

  inline-size: 100%;

  :hover {
    text-decoration: underline;
  }

  > span {
    padding: 0;

    text-align: start;
  }
`;
