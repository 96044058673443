import { notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Pagination } from "swiper/modules";

import { section as sectionData } from "shared/data/section";

import type { latestDiscoveryWidgetQueue$key } from "scmp-app/queries/__generated__/latestDiscoveryWidgetQueue.graphql";
import type { latestDiscoveryWidgetSection$key } from "scmp-app/queries/__generated__/latestDiscoveryWidgetSection.graphql";

import type { ContainerProps } from "./styles";
import {
  Container,
  ContentItemsContainer,
  MoreLink,
  MoreLinkContainer,
  Row,
  StyledContentItemHomeSecondary,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  className?: string;
  desktopItemCount?: number;
  itemCount?: number;
  overrideMoreLinkLabel?: string;
  overrideSectionName?: string;
  queueReference?: latestDiscoveryWidgetQueue$key | null;
  sectionReference?: latestDiscoveryWidgetSection$key | null;
  titleComponent?: ReactNode;
  variant?: ContainerProps["$variant"];
};

export const LatestDiscoveryWidget: FunctionComponent<Props> = ({
  className,
  desktopItemCount,
  overrideMoreLinkLabel,
  overrideSectionName,
  queueReference,
  sectionReference,
  titleComponent,
  variant = "image-last",
}) => {
  const section = useFragment(
    graphql`
      fragment latestDiscoveryWidgetSection on Section {
        urlAlias
        name
        entityId
        parentSection
      }
    `,
    sectionReference ?? null,
  );

  const queue = useFragment(
    graphql`
      fragment latestDiscoveryWidgetQueue on Queue {
        items(first: 6) {
          edges {
            node {
              ... on Article {
                entityId
                ...homeSecondaryContentItemContent
              }
            }
          }
        }
      }
    `,
    queueReference ?? null,
  );

  const latestItems = queue?.items?.edges ?? [];

  const sectionName = overrideSectionName ?? section?.name;

  const isMobile = useResponsive(theme.breakpoints.down("tablet"), false);
  const isDesktop = useResponsive(theme.breakpoints.up("desktop"), false);
  const defaultItemCount = 5;
  const items = isMobile
    ? latestItems
    : latestItems.slice(0, isDesktop ? (desktopItemCount ?? defaultItemCount) : defaultItemCount);

  const renderedItems = useMemo(() => {
    // Ignore news parent section
    const isParentSectionIsNews = section?.parentSection === sectionData.news.entityUuid;
    return items?.map(({ node }) => (
      <StyledContentItemHomeSecondary
        key={node.entityId}
        reference={node}
        withComment
        withImage
        withSection={!notEmpty(section?.parentSection) || isParentSectionIsNews}
      />
    ));
  }, [items, section?.parentSection]);

  const renderSwiper = useCallback(
    () => (
      <SwiperContainer>
        <StyledSwiper
          grid={{
            fill: "row",
            rows: 2,
          }}
          modules={[Pagination, Grid]}
          pagination={true}
          spaceBetween={16}
        >
          {renderedItems.map((item, index) => (
            <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    ),
    [renderedItems],
  );

  if (items.length === 0 || !notEmpty(section) || !notEmpty(queue)) return null;

  return (
    <Container $variant={variant} className={className}>
      {titleComponent ?? (
        <TitleContainer>
          <Title pathname={section?.urlAlias}>LATEST {sectionName} NEWS</Title>
        </TitleContainer>
      )}

      {isMobile ? (
        renderSwiper()
      ) : (
        <ContentItemsContainer>
          <Row>{renderedItems} </Row>
        </ContentItemsContainer>
      )}

      <MoreLinkContainer>
        <MoreLink pathname={section?.urlAlias}>
          {overrideMoreLinkLabel ?? `MORE ${sectionName} NEWS`}
        </MoreLink>
      </MoreLinkContainer>
    </Container>
  );
};

LatestDiscoveryWidget.displayName = "LatestDiscoveryWidget";
