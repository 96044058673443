import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { magazinesStyleFashionCarouselContentItemContent$key } from "scmp-app/queries/__generated__/magazinesStyleFashionCarouselContentItemContent.graphql";

import { Backdrop, Container, Content, Cover, Title, Topic } from "./styles";

export type Props = {
  reference: magazinesStyleFashionCarouselContentItemContent$key;
} & ContentItemRenderVariantProps;

export const ContentItemStyleFashionCarousel = forwardRef<HTMLDivElement, Props>(
  ({ className, reference: reference_, topicLinkVariant }, reference) => {
    const data = useFragment(
      graphql`
        fragment magazinesStyleFashionCarouselContentItemContent on Content {
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageSize800x1200: true
              withTopicLink: true
              withHeadline: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(data, topicLinkVariant);

    const cover = providers.coverImage({
      responsiveVariants: {
        desktopUp: "size800x1200",
        mobileUp: "size800x1200",
        tabletUp: "size800x1200",
      },
    });

    const topic = providers.topicLink?.();

    const title = providers.headline({ hideTooltip: true, preferSocialHeadline: true });

    return (
      <Container className={className} ref={reference}>
        <Topic>{topic}</Topic>
        <Cover>
          {cover}
          <Backdrop />
          <Content>
            <Title reference={data}>{title}</Title>
          </Content>
        </Cover>
      </Container>
    );
  },
);

ContentItemStyleFashionCarousel.displayName = "ContentItemStyleFashionCarousel";
