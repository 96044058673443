import styled from "@emotion/styled";
import { BlueskyEmbed, TikTokEmbed } from "@vip30/react-social-media-embed";

import { shouldForwardProp } from "scmp-app/components/schema-render/content/styles";

export const StyledBlockquote = styled("blockquote", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;

  div {
    margin-block: 0 32px;
  }
`;

export const InstagramContainer = styled.div`
  display: flex;
  justify-content: center;

  min-inline-size: 326px;
  max-inline-size: 540px;
  min-block-size: 612px;
  margin-block: 2px 28px;
  margin-inline: auto;

  iframe {
    margin-block: 0 !important;
  }

  ${props => props.theme.breakpoints.down(350)} {
    /*
      When in mobile view, need to overwrite native width of container to ensure IG post fits
      inside a 320px width view
    */
    min-inline-size: 280px;
    max-inline-size: 280px;

    > div > iframe {
      /* stylelint-disable-next-line liberty/use-logical-spec */
      min-width: 280px;
    }
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block: 12px 32px;
  }
`;

export const StyledTikTokEmbed = styled(TikTokEmbed)`
  min-inline-size: 325px;
  max-inline-size: 605px;
  min-block-size: 740px;
`;

export const StyledBlueskyEmbed = styled(BlueskyEmbed)`
  min-inline-size: 325px;
  max-inline-size: 600px;
  min-block-size: 660px;
`;

export const TikTokContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FacebookContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BlueskyContainer = styled.div`
  display: flex;
  justify-content: center;

  .bluesky-embed {
    margin-block: 0 !important;
    margin-inline: 0 !important;
  }
`;
