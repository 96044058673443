import { notEmpty } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { checkHasLiveTag } from "scmp-app/components/article/article-render/helpers";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import type { homePrimaryContentItemContent$key } from "scmp-app/queries/__generated__/homePrimaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  CoverImage,
  Header,
  Headline,
  LiveTagContainer,
  SectionContainer,
  SponsorTagContainer,
  StyledEntityLink,
  Summary,
  TopicLabel,
} from "./styles";

export type Props = {
  currentTopicReference?: string;
  isReadingTimeEnabled?: boolean;
  reference: homePrimaryContentItemContent$key;
  withCaption?: boolean;
  withComment?: boolean;
  withLiveTag?: boolean;
  withSection?: boolean;
  withTopic?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      currentTopicReference,
      isReadingTimeEnabled,
      onClick,
      reference: reference_,
      topicLinkVariant,
      withCaption = true,
      withComment = false,
      withLiveTag = false,
      withSection = true,
      withTopic = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homePrimaryContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...helpersCheckHasLiveTagArticle
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withActionBar: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withSponsorTag: true
              withVideoDurationOverlay: true
              withTopicLink: true
            )
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content, topicLinkVariant);
    const hasLiveTag = checkHasLiveTag(content);
    const isShowLiveTag = hasLiveTag && withLiveTag;

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);

    const renderHeader = () => {
      switch (true) {
        case isShowLiveTag:
          return <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>;
        case withTopic:
          return (
            <TopicLabel>
              {providers.topicLink?.({
                currentTopicReference,
                preferShortName: true,
              })}
            </TopicLabel>
          );
        case withSection:
          return <SectionContainer> {providers.sections()}</SectionContainer>;
        default:
          return null;
      }
    };

    return (
      <Container className={className} ref={reference}>
        <Content>
          <Header>{renderHeader()}</Header>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>
          {isSponsorArticle ? (
            <SponsorTagContainer>{providers.sponsorTag?.({ variant: "home" })}</SponsorTagContainer>
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                displayDateVariant: isReadingTimeEnabled ? "readingTime" : "updatedDate",
                isBookmarkVisible: false,
                isCommentsVisible: withComment ?? false,
              })}
            </ActionBar>
          )}
        </Content>
        <CoverImage>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
              withCaption,
            })}
            {providers.video?.durationOverlay({
              iconSize: "large",
              variant: "homepage",
            })}
          </StyledEntityLink>
        </CoverImage>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomePrimary";

export const ContentItemHomePrimary = withHighlightedHeadline(Component);
