import "core-js/modules/web.url-search-params.size.js";
import axios from "axios";
import "navigator.locks";
import { config } from "shared/data";
export const logout = () => {
  const url = new URL(`${config.account.scmpAccountHost}/logout`);
  url.searchParams.set("destination", encodeURIComponent(location.href));
  location.href = url.toString();
};
export const refreshToken = async () => {
  const url = `${config.account.scmpAccountHost}/refreshToken`;
  const postPromise = async () => {
    try {
      return await axios.post(url, null, {
        withCredentials: true
      });
    } catch (error) {
      const {
        response
      } = error;
      if (response && response.data.message === "missing scmp_at or scmp_rt") {
        logout();
      }
    }
  };
  // Ensure no other refresh token will be called
  return navigator.locks.request("refreshToken", async () => await postPromise());
};