import type { PageType } from "@product/scmp-sdk";

export const pathname = "/special-index";

export const page = {
  route(query: QueryParameters<{ pageType: PageType; queueName: string }>) {
    return {
      pathname,
      query: {
        ...query,
        pageType: query.pageType,
      },
    };
  },
};
