import { type FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ContentItemPostMagazineRecommended } from "scmp-app/components/content/content-item-render/variants/post-magazine-recommended";
import type { recommendedPostMagazineQuery } from "scmp-app/queries/__generated__/recommendedPostMagazineQuery.graphql";

import {
  Container,
  Header,
  HeaderContainer,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
} from "./styles";
type Props = {
  className?: string;
};

export const PostMagazineRecommended: FunctionComponent<Props> = ({ className }) => {
  const recommendData = useLazyLoadQuery<recommendedPostMagazineQuery>(
    graphql`
      query recommendedPostMagazineQuery($spotlightQueueName: String!) {
        spotlightQueue: queue(filter: { name: $spotlightQueueName }) {
          items(first: 10) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...postMagazineRecommendedItemContent
                }
              }
            }
          }
        }
      }
    `,
    { spotlightQueueName: "section_top_scmp_signatures_71" },
    { fetchPolicy: "store-or-network" },
  );

  const articles = recommendData?.spotlightQueue?.items?.edges ?? [];

  if (articles?.length === 0) {
    return null;
  }
  return (
    <Container className={className}>
      <HeaderContainer>
        <Header>RECOMMENDED</Header>
      </HeaderContainer>
      <SwiperContainer>
        <StyledSwiper
          pagination={{
            clickable: true,
          }}
          slidesPerView="auto"
          spaceBetween={24}
        >
          {articles?.map(article => (
            <StyledSwiperSlide key={article?.node?.entityId}>
              <ContentItemPostMagazineRecommended
                reference={article?.node}
                topicLinkVariant={{ type: "main" }}
              />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    </Container>
  );
};

PostMagazineRecommended.displayName = "PostMagazineRecommended";
