/**
 * @generated SignedSource<<138b1fd6098f0c69c1aed57ffa631bc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSectionWidgetQuery$data = {
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"asiaDiscoveryWidgetQuery" | "chinaFutureTechDiscoveryWidgetQuery" | "chinaScienceDiscoveryWidgetQuery" | "commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "focusArticleHomeFocusArticleQuery" | "mostPopularQueueQuery" | "multimediaQuery" | "openQuestionsSeriesDiscoveryWidgetQuery" | "plusWidgetQuery" | "thisWeekInAsiaDiscoveryWidgetQuery" | "trendingTopicTopic">;
  readonly " $fragmentType": "hooksSectionWidgetQuery";
};
export type hooksSectionWidgetQuery$key = {
  readonly " $data"?: hooksSectionWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSectionWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSectionWidgetQuery"
};

(node as any).hash = "7552dae10b66662b7791e45744887b63";

export default node;
