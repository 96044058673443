import { section as sectionData } from "shared/data/section";

import type { SectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSectionWidgets(entityId);

  // include this widget when plus is enabled and the widget is the fallback widget
  const shouldIncludePlus = widgets.includes("plus");
  const plusFallbackWidget = getPlusFallbackWidget(entityId);
  const checkShouldIncludeWidget = (widget: SectionWidget) =>
    widgets.includes(widget) || (shouldIncludePlus && plusFallbackWidget === widget);

  return {
    includeAsiaDiscoveryWidget: checkShouldIncludeWidget("asia-discovery"),
    includeChinaFutureTechDiscoveryWidget: checkShouldIncludeWidget("china-future-tech-discovery"),
    includeChinaScienceDiscoveryWidget: checkShouldIncludeWidget("china-science-discovery"),
    includeComment: checkShouldIncludeWidget("comment"),
    includeFocusArticle: checkShouldIncludeWidget("focus-article"),
    includeMostPopular: checkShouldIncludeWidget("most-popular"),
    includeMultimedia: checkShouldIncludeWidget("multimedia"),
    includeOpenQuestionsSeriesDiscoveryWidget: checkShouldIncludeWidget(
      "open-questions-series-discovery",
    ),
    includePlus: shouldIncludePlus,
    includeThisWeekInAsiaDiscovery: checkShouldIncludeWidget("this-week-in-asia-discovery"),
    includeTrendingTopics: checkShouldIncludeWidget("trending-topics"),
  };
};

export const getSectionWidgets = (entityId: string): SectionWidget[] => {
  switch (entityId) {
    case sectionData.asia.entityId:
      return [
        "this-week-in-asia-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.business.entityId:
    case sectionData.economy.entityId:
    case sectionData.hongKong.entityId:
      return [
        "plus",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.china.entityId:
      return [
        "china-science-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.chinaFutureTech.entityId:
      return [
        "focus-article",
        "plus",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.comment.entityId:
    case sectionData.kPop.entityId:
    case sectionData.sport.entityId:
      return ["recommended-for-you", "comment", "multimedia", "trending-topics"];
    case sectionData.lifestyle.entityId:
      return ["post-magazine", "comment", "recommended-for-you", "multimedia", "trending-topics"];
    case sectionData.news.peopleAndCulture.entityId:
      return ["post-magazine", "recommended-for-you", "comment", "multimedia", "trending-topics"];
    case sectionData.olympic.entityId:
      return ["recommended-for-you", "multimedia", "most-popular"];
    case sectionData.tech.entityId:
      return [
        "china-future-tech-discovery",
        "recommended-for-you",
        "comment",
        "multimedia",
        "trending-topics",
      ];
    case sectionData.thisWeekInAsia.entityId:
      return [
        "asia-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.us.entityId:
    case sectionData.us.usElections.entityId:
      return ["focus-article", "comment", "recommended-for-you", "multimedia", "trending-topics"];
    case sectionData.world.entityId:
      return [
        "open-questions-series-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    default:
      return ["comment", "recommended-for-you", "multimedia", "trending-topics", "most-popular"];
  }
};

export const getPlusFallbackWidget = (entityId: string) => {
  const fallbackWidget: SectionWidget =
    {
      [sectionData.chinaFutureTech.entityId]: "china-science-discovery" as const,
    }[entityId] ?? "china-future-tech-discovery";

  return fallbackWidget;
};
