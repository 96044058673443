import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingHomePageTakeOverType } from "scmp-app/components/advertisement/ad-slots/types";
import { TakeoverAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot";
import { useTakeoverBottomAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot/hooks";
import { AfterPageLayout } from "scmp-app/components/after-page-layout";
import { AppFooter } from "scmp-app/components/app-footer";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import type { sectionStyleContentQuery$key } from "scmp-app/queries/__generated__/sectionStyleContentQuery.graphql";

import { SectionStyleMain } from "./section-style-main";
import { SectionStyleNewsletter } from "./section-style-newsletter";
import { StoryCardCarouselWidget } from "./section-style-story-card-carousel-widget";
import { Container } from "./styles";

export type Props = {
  reference: sectionStyleContentQuery$key;
};
export const SectionStyle: FunctionComponent<Props> = ({ reference }) => {
  const content = useFragment(
    graphql`
      fragment sectionStyleContentQuery on Query
      @argumentDefinitions(articlesQueueName: { type: "String!" }) {
        ...sectionStyleMainQuery @arguments(articlesQueueName: $articlesQueueName)
      }
    `,
    reference,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [
        [970, 250],
        [1, 1],
        [480, 360],
        [480, 270],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        hpto: TargetingHomePageTakeOverType.Top,
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [[300, 50], [300, 100], [320, 50], [320, 100], "fluid"],
      targeting: {
        ...baseAdvertisement.targeting,
        hpto: TargetingHomePageTakeOverType.Top,
      },
      zone: baseAdvertisement.zone,
    },
  });

  const { isEnableTakeoverBottomAdSlot } = useTakeoverBottomAdSlot({
    variant: MagazinesStyleVariant,
  });

  return (
    <Container>
      <SectionStyleMain reference={content} />
      <SectionStyleNewsletter />
      <ClientSideSuspense>
        <AppFooter variant="magazines-style" />
      </ClientSideSuspense>
      <ClientSideSuspense>
        <AfterPageLayout>
          {isEnableTakeoverBottomAdSlot ? (
            <TakeoverAdSlot adSlotProps={baseAdvertisement} variant={MagazinesStyleVariant} />
          ) : (
            <StoryCardCarouselWidget />
          )}
        </AfterPageLayout>
      </ClientSideSuspense>
    </Container>
  );
};

SectionStyle.displayName = "SectionStyle";
