import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { setLineClamp } from "@product/scmp-sdk";

import { StyledBaseLink } from "scmp-app/components/schema-render/common/link/styles";

import { HTMLSchemaRender } from "./helper";

export const Container = styled.div`
  padding: 20px;

  border: 1px solid #bbbbbb;
  border-radius: 8px;

  background: #f6f6f6;

  ${props => props.theme.breakpoints.up("tablet")} {
    max-inline-size: 600px;
    margin-inline: auto;
  }
`;

export const ContentContainer = styled.div``;

export const ShowButton = styled.button`
  display: flex;
  align-items: center;

  margin-block-start: 8px;

  color: #4585ff;
  font-size: 14px;
  line-height: 140%;

  background-color: transparent;

  cursor: pointer;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

const getHaveMoreContent = (isHavingMoreContent?: boolean, isShowFullProfile?: boolean) => css`
  ${isHavingMoreContent
    ? css`
        ${isShowFullProfile
          ? css`
              ${ShowButton} {
                display: none;
              }
            `
          : css`
              ${ContentContainer} {
                ${setLineClamp(3, "19.6px")}

                &:after {
                  position: absolute;
                  content: "...";
                }
              }
            `}
      `
    : css`
        ${ShowButton} {
          display: none;
        }
      `}
`;

type DescriptionContainerProps = {
  $collapsedOnDesktop?: boolean;
  $isHavingMoreContent?: boolean;
  $isShowFullProfile?: boolean;
};
export const DescriptionContainer = styled.div<DescriptionContainerProps>`
  position: relative;

  ${props => css`
    ${props.theme.breakpoints.only("mobile")} {
      ${getHaveMoreContent(props.$isHavingMoreContent, props.$isShowFullProfile)}
    }
  `}

  ${props => props.theme.breakpoints.up("desktop")} {
    display: block;

    max-block-size: none;
  }
`;

export const StyledHTMLSchemaRender = styled(HTMLSchemaRender)`
  font-size: 14px;
  line-height: 19.6px;

  ${StyledBaseLink} {
    color: #2c4692;
    font-weight: 700;
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-block-end: 20px;
  }
`;
