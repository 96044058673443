import { useAtomValue } from "jotai";
import { type FunctionComponent, type ReactNode, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { section as sectionData } from "shared/data/section";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { plusWidgetQuery$key } from "scmp-app/queries/__generated__/plusWidgetQuery.graphql";

import { usePlusWidgetConfig } from "./hooks";
import { PlusWidgetHasAccess } from "./plus-widget-has-access";
import { PlusWidgetWithoutAccess } from "./plus-widget-without-access";
import { Container, ContentContainer, ContentWrapper, Placeholder } from "./styles";

export type PlusWidgetsSampleContentType =
  | "homepage"
  | null
  | typeof sectionData.business.aiEngineName
  | typeof sectionData.china.aiEngineName
  | typeof sectionData.chinaFutureTech.aiEngineName
  | typeof sectionData.economy.aiEngineName
  | typeof sectionData.hongKong.aiEngineName;

type Props = {
  className?: string;
  fallbackWidget?: () => ReactNode;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetQuery$key;
  sampleContent: PlusWidgetsSampleContentType;
};

export const PlusWidget: FunctionComponent<Props> = ({
  className,
  fallbackWidget,
  pageType,
  reference,
  sampleContent,
}) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const hasScmpPlusAccessRight = asyncRosettaState?.result?.hasScmpPlusAccessRight ?? false;
  const data = useFragment(
    graphql`
      fragment plusWidgetQuery on Query
      @argumentDefinitions(
        applicationId: { type: "String!" }
        dailyPulseTypeUuId: { type: "String!" }
        highlightQueueName: { type: "String!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
      ) {
        ...plusWidgetHasAccessQuery
          @arguments(
            applicationId: $applicationId
            dailyPulseTypeUuId: $dailyPulseTypeUuId
            highlightQueueName: $highlightQueueName
            plusNewsAgendaEndDate: $plusNewsAgendaEndDate
            plusNewsAgendaStartDate: $plusNewsAgendaStartDate
            plusNewsAgendaTypes: $plusNewsAgendaTypes
          )
        ...hooksPlusWidgetConfigQuery
      }
    `,
    reference,
  );

  const { plusWidgetConfig } = usePlusWidgetConfig(data);

  const isWidgetDisabled = useMemo(
    () =>
      plusWidgetConfig?.[
        hasScmpPlusAccessRight ? "withAccess" : "withoutAccess"
      ]?.disabledDateRanges?.some(({ end, start }) => {
        const isAfterStart = new Date() >= new Date(start);
        const isBeforeEnd = end ? new Date() <= new Date(end) : true; // If no end date, assume open-ended
        return isAfterStart && isBeforeEnd;
      }) ?? false,
    [hasScmpPlusAccessRight, plusWidgetConfig],
  );

  if (asyncRosettaState?.status !== "success") {
    return (
      <Container className={className}>
        <Placeholder />
      </Container>
    );
  }

  if (isWidgetDisabled) {
    return fallbackWidget?.();
  }

  return (
    <Container className={className}>
      <ClientSideSuspense fallback={<Placeholder />}>
        <ContentContainer>
          <ContentWrapper>
            {hasScmpPlusAccessRight ? (
              <PlusWidgetHasAccess pageType={pageType} reference={data} />
            ) : (
              <PlusWidgetWithoutAccess sampleContent={sampleContent} />
            )}
          </ContentWrapper>
        </ContentContainer>
      </ClientSideSuspense>
    </Container>
  );
};

PlusWidget.displayName = "PlusWidget";
