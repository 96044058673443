import { graphql, useFragment } from "react-relay";

import type { hooksPlusWidgetConfigQuery$key } from "scmp-app/queries/__generated__/hooksPlusWidgetConfigQuery.graphql";

export const usePlusWidgetConfig = (reference: hooksPlusWidgetConfigQuery$key) => {
  const data = useFragment(
    graphql`
      fragment hooksPlusWidgetConfigQuery on Query {
        appConfig(filter: { entityId: "scmp_pwa_settings" }) {
          json
        }
      }
    `,
    reference,
  );

  const plusWidgetConfig = (data.appConfig?.json as PlusWidgetAppConfig).plus_widget;

  return {
    plusWidgetConfig,
  };
};

type PlusWidgetAppConfig = {
  plus_widget: Record<
    "withAccess" | "withoutAccess",
    {
      disabledDateRanges: [{ end?: Date; start: Date }];
    }
  >;
};
