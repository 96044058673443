import { useMemo } from "react";

import type { NavigatorInfo } from "scmp-app/lib/hooks";

export const useRosettaPianoExecution = (
  navigatorInfo: NavigatorInfo | undefined,
  navigatorInfoLoaded: boolean | undefined,
) => {
  const shouldSkipPianoExecution = useMemo(() => {
    /*
    https://scmp-product-tech.atlassian.net/browse/SCMPWEB-6604?focusedCommentId=100593
    Block execution:
    1. Light house crawler
    2. bot score between 1-29 and not a verified bot
     */
    if (navigatorInfo?.isLightHouseCrawler) return true;
    if (navigatorInfo?.isBot) return false; // Verified bot
    return navigatorInfo?.botScoreGroup === "1-29";
  }, [navigatorInfo]);

  return {
    isRosettaPianoExecutionReady: !!navigatorInfoLoaded,
    shouldSkipPianoExecution,
  };
};
