import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { authorNameAuthor$key } from "scmp-app/queries/__generated__/authorNameAuthor.graphql";

import { StyledAuthorName, StyledEntityLink } from "./styles";

export type Props = {
  className?: string;
  onClick?: (entityUuid: string) => void;
  reference: authorNameAuthor$key;
  withoutLink?: boolean;
};

export const AuthorName: FunctionComponent<Props> = ({
  className,
  onClick,
  reference: reference_,
  withoutLink,
}) => {
  const author = useFragment(
    graphql`
      fragment authorNameAuthor on Author {
        ...entityLink
        name
        entityUuid
      }
    `,
    reference_,
  );
  return (
    <>
      {withoutLink ? (
        <StyledAuthorName className={className} onClick={() => onClick?.(author.entityUuid)}>
          {author.name}
        </StyledAuthorName>
      ) : (
        <StyledEntityLink className={className} reference={author}>
          {author.name}
        </StyledEntityLink>
      )}
    </>
  );
};

AuthorName.displayName = "AuthorName";
