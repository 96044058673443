import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { section } from "shared/data";

import type { helpersCheckIsPostiesArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsPostiesArticle.graphql";

export const checkIsPostiesArticle = (reference: helpersCheckIsPostiesArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPostiesArticle on Article @inline {
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const sections = article?.sections?.flatMap(section =>
    section?.value?.map(value => value && value.entityId),
  );
  return notEmpty(sections?.includes(section.posties.entityId));
};

export const extractAfterPrefix = (href: string, prefixes: string[]) => {
  let hashLink = null;
  try {
    const url = new URL(href);
    hashLink = url.hash;
  } catch {
    hashLink = href;
  }

  for (const prefix of prefixes) {
    const regex = new RegExp(`^#${prefix}-(.+)`);
    const match = hashLink.match(regex);
    if (match) {
      return { documentId: match[1], hash: hashLink, prefix };
    }
  }
  return null;
};
