import styled from "@emotion/styled";

import { EntityLink } from "scmp-app/components/entity-link";

export const StyledEntityLink = styled(EntityLink)`
  margin: 0;

  color: #000d37;
  line-height: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledAuthorName = styled.div``;
