import type { FunctionComponent } from "react";

import { config } from "shared/data";

import { SectionSeo } from "scmp-app/components/section/section-seo";
import type { sectionSeoSection$key } from "scmp-app/queries/__generated__/sectionSeoSection.graphql";

type Props = {
  reference: sectionSeoSection$key;
};

export const SectionStyleSeo: FunctionComponent<Props> = ({ reference }) => (
  <SectionSeo
    reference={reference}
    twitter={{
      site: `@${config.social.twitter.style.accountName}`,
    }}
  />
);

SectionStyleSeo.displayName = "SectionStyleSeo";
