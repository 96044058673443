/**
 * @generated SignedSource<<148085c9bec979b58f03011fa00bfa1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type magazinesStyleFashionCarouselContentItemContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "magazinesStyleFashionCarouselContentItemContent";
};
export type magazinesStyleFashionCarouselContentItemContent$key = {
  readonly " $data"?: magazinesStyleFashionCarouselContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleFashionCarouselContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "magazinesStyleFashionCarouselContentItemContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize800x1200",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withTopicLink",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "d23830c7673f2ae3507047eeec011864";

export default node;
