import { differenceInHours } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import uniqBy from "lodash/uniqBy";
import { graphql, readInlineData } from "react-relay";

import { config } from "shared/data";

import type { helpersIsPublishedWithin24HoursQueueItemConnection$key } from "scmp-app/queries/__generated__/helpersIsPublishedWithin24HoursQueueItemConnection.graphql";
import type {
  helpersStyleArticleListQuery$data,
  helpersStyleArticleListQuery$key,
} from "scmp-app/queries/__generated__/helpersStyleArticleListQuery.graphql";

import type { SubSection } from "./consts";
import { MaximumArticleNumberPerSection, SectionNameMap } from "./consts";
import type { Section } from "./types";

type ArticleList = NonNullable<
  NonNullable<NonNullable<helpersStyleArticleListQuery$data>[SubSection]>["items"]
>["edges"];

export type UniqueArticlesPerSectionItem = {
  articleEdges: ArticleList;
  isPublishedWithin24Hours: boolean;
  section: Section;
};

type GetUniqueArticlesPerSectionParameters = {
  currentSectionEntityId: string;
  reference: helpersStyleArticleListQuery$key;
};

// Remember to check the queue in Drupal CMS and click the save to sync the articles(For staging).
export function getUniqueArticlesPerSection(parameters: GetUniqueArticlesPerSectionParameters) {
  const { currentSectionEntityId, reference } = parameters;
  const data = readInlineData(query, reference);

  const currentSubSectionName =
    Object.values(SectionNameMap).find(section => section.entityId === currentSectionEntityId)
      ?.pathname ?? "luxury";

  const subSectionToArticleListPairList: Array<
    [SubSection, helpersStyleArticleListQuery$data[SubSection]]
  > = uniqBy(
    [
      [currentSubSectionName, data[currentSubSectionName]],
      ["luxury", data.luxury],
      ["fashion", data.fashion],
      ["beauty", data.beauty],
      ["people", data.people],
      ["lifestyle", data.lifestyle],
    ],
    ([sectionName]) => sectionName,
  );

  const uniqueArticlesPerSection = subSectionToArticleListPairList.reduce(
    (accumulator, [sectionName, queue]) => {
      const selectedArticleEdges = (queue?.items?.edges ?? [])
        .filter(edge => !accumulator.selectedEntityIds.includes(edge.node.entityId ?? ""))
        .slice(0, MaximumArticleNumberPerSection);

      return {
        data: [
          ...accumulator.data,
          {
            articleEdges: selectedArticleEdges,
            isPublishedWithin24Hours: isPublishedWithin24Hours(queue?.items),
            section: SectionNameMap[sectionName],
          },
        ],
        selectedEdges: [...accumulator.selectedEdges, ...selectedArticleEdges],
        selectedEntityIds: [
          ...accumulator.selectedEntityIds,
          ...selectedArticleEdges.map(edge => edge.node.entityId ?? ""),
        ],
      };
    },
    {
      data: [],
      selectedEdges: [],
      selectedEntityIds: [],
    } as {
      data: UniqueArticlesPerSectionItem[];
      selectedEdges: ArticleList;
      selectedEntityIds: string[];
    },
  );

  return uniqueArticlesPerSection;
}

function isPublishedWithin24Hours(
  reference?: helpersIsPublishedWithin24HoursQueueItemConnection$key | null,
) {
  if (!reference) return false;

  const articles = readInlineData(
    graphql`
      fragment helpersIsPublishedWithin24HoursQueueItemConnection on QueueItemConnection @inline {
        edges {
          node {
            ... on Article {
              publishedDate
            }
          }
        }
      }
    `,
    reference,
  );
  return articles.edges.some(({ node: article }) => {
    if (article?.publishedDate) {
      const now = toZonedTime(new Date(), config.date.defaultTimeZone);
      const hours = differenceInHours(now, new Date(article.publishedDate));
      return hours <= 24;
    }
    return false;
  });
}

const query = graphql`
  fragment helpersStyleArticleListQuery on Query @inline {
    luxury: queue(filter: { entityId: "1070" }) {
      items(first: 4, exclude: { types: VIDEO })
        @connection(key: "helpersStyleArticleListQuery__items") {
        __typename
        ...helpersIsPublishedWithin24HoursQueueItemConnection
        edges {
          ...storyCardLargeLayoutQueueItemsEdge
          node {
            ... on Article {
              entityId
              ...storyCardItemArticle
              ...itemStoryCardCarouselWidgetDisplayItemArticle
            }
          }
        }
      }
    }

    fashion: queue(filter: { name: "section_top_516294" }) {
      items(first: 8, exclude: { types: VIDEO })
        @connection(key: "helpersStyleArticleListQuery__items") {
        __typename
        ...helpersIsPublishedWithin24HoursQueueItemConnection
        edges {
          ...storyCardLargeLayoutQueueItemsEdge
          node {
            ... on Article {
              entityId
              ...storyCardItemArticle
              ...itemStoryCardCarouselWidgetDisplayItemArticle
            }
          }
        }
      }
    }
    beauty: queue(filter: { name: "section_top_516298" }) {
      items(first: 8, exclude: { types: VIDEO })
        @connection(key: "helpersStyleArticleListQuery__items") {
        __typename
        ...helpersIsPublishedWithin24HoursQueueItemConnection
        edges {
          ...storyCardLargeLayoutQueueItemsEdge
          node {
            ... on Article {
              entityId
              ...storyCardItemArticle
              ...itemStoryCardCarouselWidgetDisplayItemArticle
            }
          }
        }
      }
    }
    people: queue(filter: { name: "section_top_516301" }) {
      items(first: 8, exclude: { types: VIDEO })
        @connection(key: "helpersStyleArticleListQuery__items") {
        __typename
        ...helpersIsPublishedWithin24HoursQueueItemConnection
        edges {
          ...storyCardLargeLayoutQueueItemsEdge
          node {
            ... on Article {
              entityId
              ...storyCardItemArticle
              ...itemStoryCardCarouselWidgetDisplayItemArticle
            }
          }
        }
      }
    }
    lifestyle: queue(filter: { name: "section_top_516305" }) {
      items(first: 8, exclude: { types: VIDEO })
        @connection(key: "helpersStyleArticleListQuery__items") {
        __typename
        ...helpersIsPublishedWithin24HoursQueueItemConnection
        edges {
          ...storyCardLargeLayoutQueueItemsEdge
          node {
            ... on Article {
              entityId
              ...storyCardItemArticle
              ...itemStoryCardCarouselWidgetDisplayItemArticle
            }
          }
        }
      }
    }
  }
`;
