import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { Swiper } from "scmp-app/components/swiper";

import IconArrowNext from "./icon-arrow-next.svg?url";
import IconArrowPrevious from "./icon-arrow-previous.svg?url";

export const Container = styled.div`
  margin-inline: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-block-end: 24px;
  margin-inline: 20px;
  padding-block-start: 40px;
  border-block-start: 1px solid #777777;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 40px;
    margin-inline: 40px;
  }
`;

export const Header = styled.div`
  color: #111111;
  font-weight: 700;
  font-size: 20px;
  font-family: ${fontIndivisibleVariable};
  letter-spacing: -0.4px;
  font-variation-settings: "wght" 700;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.48px;
  }
`;

export const SwiperContainer = styled.div`
  position: relative;

  inline-size: 100%;
  min-inline-size: 0;
  margin-inline: auto;
  padding-inline-start: 20px;

  &::before {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";

    block-size: 100%;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    margin-inline-end: 24px;
    padding-inline: 16px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    max-inline-size: 100vw;
    padding-block: 0 34px;

    .swiper-button-prev {
      inset-inline-start: 0;

      background-image: url(${IconArrowPrevious});
    }
    .swiper-button-next {
      inset-inline-start: 20px;

      direction: rtl;

      background-image: url(${IconArrowNext});

      ${props => props.theme.breakpoints.up("tablet")} {
        inset-inline-start: 0;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      inline-size: 48px;
      block-size: 48px;

      box-shadow: none;

      opacity: 1;

      user-select: none;

      &::after {
        display: none;
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .swiper-pagination {
      inset-block-end: 0;
    }

    .swiper-pagination-bullet {
      inline-size: 6px;
      block-size: 6px;
      margin-inline-start: 0 !important;

      background: #cccccc;

      opacity: 0.3;

      &:not(:last-child) {
        margin-inline-end: 12px;
      }

      ${props => props.theme.breakpoints.up("tablet")} {
        inline-size: 10px;
        block-size: 10px;
      }
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }

    ${props => props.theme.breakpoints.up("tablet")} {
      padding-inline: 24px;
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  &.swiper-slide {
    inline-size: 300px;
    ${props => props.theme.breakpoints.up("tablet")} {
      inline-size: 384px;
    }
  }
`;
