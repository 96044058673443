import { notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Pagination } from "swiper/modules";

import { tracking } from "shared/data";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { discoveryWidgetPostMagazineQueueQuery$key } from "scmp-app/queries/__generated__/discoveryWidgetPostMagazineQueueQuery.graphql";

import Logo from "./logo.svg";
import {
  Container,
  Footer,
  Header,
  ItemsContainer,
  MoreLink,
  Publication,
  StyledContentItemPostMagazineDiscovery,
  StyledContentItemPostMagazineDiscoverySponsor,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: discoveryWidgetPostMagazineQueueQuery$key;
};

export const PostMagazineDiscoveryWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment discoveryWidgetPostMagazineQueueQuery on Query {
        postMagazineLast7Days: queue(
          filter: { name: "scmp_trending_section_scmp_trending_section_postmag_last_7_days" }
        ) {
          items(first: 6) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...postMagazineDiscoveryItemContent
                }
              }
            }
          }
        }
        postMagazineSponsor: queue(filter: { name: "discovery_module_discovery_module_71" }) {
          items(first: 1) {
            edges {
              node {
                ... on Article {
                  ...postMagazineDiscoveryItemContent
                }
              }
            }
          }
        }
        postMagazineSection: section(filter: { entityId: "71" }) {
          ...entityLink
        }
      }
    `,
    reference,
  );

  const currentPageType = useCurrentPageType();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"));

  const renderedItems = useMemo(() => {
    const [sponsorItem] = data?.postMagazineSponsor?.items?.edges ?? [];
    const last7DaysItems = data?.postMagazineLast7Days?.items?.edges ?? [];

    const sponsorNode = sponsorItem?.node ? (
      <StyledContentItemPostMagazineDiscoverySponsor key="sponsor" reference={sponsorItem?.node} />
    ) : null;
    const [firstNode, ...resetNodes] = last7DaysItems.map(({ node }, index) => (
      <StyledContentItemPostMagazineDiscovery
        key={node.entityId}
        reference={node}
        withSummary={index === 0}
      />
    ));

    const results = isMobile
      ? [firstNode, sponsorNode, ...resetNodes]
      : [firstNode, ...resetNodes, sponsorNode];

    return results.filter(notEmpty);
  }, [
    data?.postMagazineLast7Days?.items?.edges,
    data?.postMagazineSponsor?.items?.edges,
    isMobile,
  ]);

  const renderSwiper = useCallback(() => {
    // originally we have any array of articles, e.g. { [0], [1], [2], [3], [4], [5] }
    // with swiper, the render sequence would be:
    //     { [0] } { [1] } { [2] }
    //     { [3] } { [4] } { [5] }
    // we need to remap the items into
    //     { [0] } { [2] } { [4] }
    //     { [1] } { [3] } { [5] }
    const remappedItems = [0, 2, 4, 1, 3, 5].map(index => renderedItems?.[index]).filter(notEmpty);
    return (
      <SwiperContainer>
        <StyledSwiper
          grid={{
            fill: "row",
            rows: 2,
          }}
          modules={[Pagination, Grid]}
          pagination={true}
          spaceBetween={16}
        >
          {remappedItems.map((item, index) => (
            <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    );
  }, [renderedItems]);

  if (renderedItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.PostMagazine,
        pgtype: currentPageType,
      }}
    >
      <Container className={className}>
        <Header>
          <Title>HIGHLIGHTS FROM</Title>
          <Logo />
        </Header>
        <ItemsContainer>{isMobile ? renderSwiper() : renderedItems}</ItemsContainer>
        <Footer>
          <MoreLink reference={data.postMagazineSection}>MORE IN POSTMAG</MoreLink>
          <Publication>{`Content from SCMP's Specialist Publications`}</Publication>
        </Footer>
      </Container>
    </BaseLinkContextProvider>
  );
};

PostMagazineDiscoveryWidget.displayName = "PostMagazineDiscoveryWidget";
