import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";
import qs from "qs";

import { config } from "shared/data";
import { logout } from "shared/lib/account";

import type {
  BookmarkActionResponse,
  BookmarkPayload,
  GetBookmarksResponse,
  UserBookmarkList,
} from "./types";
import { BookmarkTypes } from "./types";
import { generateNewList } from "./utils";

const defaultConfig: AxiosRequestConfig = {
  withCredentials: true,
};

export const fetchList = async () => {
  const query = qs.stringify({ detail: false });

  try {
    const resp = await axios.get<GetBookmarksResponse>(
      `${config.general.userFollowHost}/user-bookmark-list?${query}`,
      {
        ...defaultConfig,
      },
    );

    return resp.data.userBookmarkList;
  } catch (error) {
    const { response } = error as Required<AxiosError>;
    if (response?.status === 401) {
      logout();
    }
    return [] as UserBookmarkList;
  }
};

export const create = async (
  previousList: GetBookmarksResponse["userBookmarkList"] | undefined,
  parameter: BookmarkPayload,
) => {
  try {
    const _ = await axios.post<BookmarkActionResponse>(
      `${config.general.userFollowHost}/bookmark`,
      {
        messageItem: {
          type: BookmarkTypes.Node,
          ...parameter,
        },
      },
      {
        ...defaultConfig,
      },
    );
  } catch (error) {
    const { response } = error as Required<AxiosError>;
    if (response?.status === 401) {
      logout();
    }
  }

  return generateNewList(previousList, parameter);
};
