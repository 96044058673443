import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { twoSectionTopWidgetQueue$key } from "scmp-app/queries/__generated__/twoSectionTopWidgetQueue.graphql";

import {
  Container,
  Primary,
  Secondary,
  StyledContentItemHomeSecondary,
  StyledContentItemSectionTopLeadSecondary,
} from "./styles";

type Props = {
  preferTopicLabel?: boolean;
  reference: twoSectionTopWidgetQueue$key;
  withLiveTag?: boolean;
  withTopic?: boolean;
} & ContentItemRenderVariantProps;

export const SectionTopWidgetTwo: FunctionComponent<Props> = ({
  className,
  onClick,
  preferTopicLabel,
  reference,
  withLiveTag,
  withTopic,
}) => {
  const data = useFragment(
    graphql`
      fragment twoSectionTopWidgetQueue on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...homeSecondaryContentItemContent
            ...sectionTopLeadSecondaryContentItemContent
          }
        }
      }
    `,
    reference,
  );

  const items = data?.map(({ node }) => node).filter(node => notEmpty(node)) ?? [];

  if (items.length === 0) return null;

  const [leading, ...rest] = items;

  return (
    <Container className={className}>
      <Primary>
        {leading && (
          <StyledContentItemSectionTopLeadSecondary
            onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
            preferTopicLabel={preferTopicLabel}
            reference={leading}
            withComment
            withLiveTag={withLiveTag ?? true}
            withTopic={withTopic}
          />
        )}
      </Primary>
      {rest?.length > 0 && (
        <Secondary>
          {rest
            ?.slice(0, 4)
            ?.map((item, index) => (
              <StyledContentItemHomeSecondary
                key={index}
                onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
                reference={item}
                withComment
                withLiveTag={withLiveTag ?? true}
              />
            ))}
        </Secondary>
      )}
    </Container>
  );
};

SectionTopWidgetTwo.displayName = "SectionTopWidgetTwo";
