import type { FunctionComponent } from "react";
import type { ItemProps } from "react-photoswipe-gallery";
import { Item } from "react-photoswipe-gallery";

import { useImageDimension } from "./hooks";

export type Props = {
  src?: string;
  title?: string;
} & Pick<ItemProps<HTMLElement>, "children">;

export const GalleryItem: FunctionComponent<Props> = ({ children, src, title }) => {
  const dimension = useImageDimension(src);
  return (
    <Item caption={title} height={dimension.height} original={src} width={dimension.width}>
      {children}
    </Item>
  );
};

GalleryItem.displayName = "GalleryItem";
