import styled from "@emotion/styled";
import { fontIndivisibleVariable, fontRobotoCondensed } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/grid";
import { Swiper, SwiperSlide } from "swiper/react";

import { ContentItemPostMagazineDiscovery } from "scmp-app/components/content/content-item-render/variants/post-magazine-discovery";
import {
  ActionBar,
  CoverImage,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
  Summary,
} from "scmp-app/components/content/content-item-render/variants/post-magazine-discovery/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import {
  SponsorName,
  SponsorTagContainer,
  SponsorType,
} from "scmp-app/components/sponsor-tag/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;

  padding-block: 24px;
  padding-inline: 20px;

  border: 1px solid #000000;
  border-radius: 16px;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-items: center;
  }
`;

export const Title = styled.div`
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  letter-spacing: 1.4px;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.only("tablet")} {
    text-align: center;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block-end: 32px;

    .swiper-pagination {
      inset-block-end: 0;

      .swiper-pagination-bullet {
        margin: 0;

        background-color: #bbbbbb;

        opacity: 0.5;
        &:not(:last-child) {
          margin-inline-end: 8px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;

        opacity: 1;
      }
    }
  }
`;

export const StyledContentItemPostMagazineDiscovery = styled(ContentItemPostMagazineDiscovery)`
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-block-end: 16px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.2);

  ${props => props.theme.breakpoints.only("tablet")} {
    margin-block-end: 16px;
    ${StyledCoverEntityLink} {
      margin-block-end: 12px;
    }
  }

  ${Summary} {
    display: none;
    ${props => props.theme.breakpoints.only("tablet")} {
      display: block;
    }
  }
`;

export const StyledContentItemPostMagazineDiscoverySponsor = styled(
  StyledContentItemPostMagazineDiscovery,
)``;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;

  block-size: calc((100% - 16px) / 2) !important;
`;

export const MoreLink = styled(EntityLink)`
  align-self: center;

  padding-block: 7px;
  padding-inline: 8px;

  color: #111111;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  border: 1px solid #111111;
  border-radius: 2px;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-self: flex-start;
  }

  ${props => props.theme.breakpoints.only("tablet")} {
    margin-block-start: 0;
  }
`;

export const SwiperContainer = styled.div`
  max-inline-size: 100%;

  ${props => props.theme.breakpoints.only("tablet")} {
    display: none;
  }
`;

export const ItemsContainer = styled.div`
  inline-size: 100%;

  ${StyledContentItemPostMagazineDiscovery}, ${StyledContentItemPostMagazineDiscoverySponsor} {
    ${SponsorTagContainer} {
      gap: 0;

      ${SponsorType} {
        color: #111111;
        font-weight: 400;
        font-size: 12px;
        font-family: ${fontIndivisibleVariable};
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.22px;
      }

      ${SponsorName} {
        color: #111111;
        font-weight: 400;
        font-variation-settings: "wght" 700;

        font-size: 12px;
        font-family: ${fontIndivisibleVariable};
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.22px;
      }
    }
  }

  ${props => props.theme.breakpoints.only("tablet")} {
    display: grid;
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    grid-template-columns: minmax(320px, 1fr) 1fr;
    gap: 0 24px;

    > :nth-child(n + 2) {
      display: grid;
      grid:
        "cover-image-link . headline-link" min-content
        "cover-image-link . action-bar" min-content
        / 96px 12px 1fr;
      align-items: flex-start;

      margin-block-end: 16px;
      padding-block-end: 16px;

      border-block-end: 1px solid rgba(0, 0, 0, 0.2);
      ${StyledCoverEntityLink} {
        grid-area: cover-image-link;

        inline-size: 96px;
        block-size: 96px;
        margin-block: 0 auto;
      }

      ${ActionBar} {
        grid-area: action-bar;

        margin-block: 12px 0;
      }

      ${StyledHeadlineEntityLink} {
        grid-area: headline-link;
      }
    }

    > :first-child {
      grid-row: 1 / span 5;
      grid-column: 1 / 1;

      margin-block-end: 0;
      padding-block-end: 0;

      border: none;
      ${StyledCoverEntityLink} {
        aspect-ratio: 1/1;

        ${CoverImage} {
          aspect-ratio: 1/1;
        }
      }

      ${ActionBar} {
        margin-block: 12px 0;
      }

      ${Summary} {
        margin-block-start: 12px;
      }
    }

    :not(:has(${StyledContentItemPostMagazineDiscoverySponsor})) {
      ${StyledContentItemPostMagazineDiscovery} {
        :nth-child(n + 6) {
          display: none;
        }
        :nth-child(5) {
          border-block-end: none;
        }
      }
    }

    :has(${StyledContentItemPostMagazineDiscoverySponsor}) {
      ${StyledContentItemPostMagazineDiscovery} {
        :nth-child(n + 5) {
          display: none;
        }
      }
      ${StyledContentItemPostMagazineDiscoverySponsor} {
        border-block-end: none;
      }
    }
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;
    flex-direction: column;
    gap: 24px;

    :not(:has(${StyledContentItemPostMagazineDiscoverySponsor})) {
      ${StyledContentItemPostMagazineDiscovery} {
        :nth-child(n + 6) {
          display: none;
        }
      }
    }

    :has(${StyledContentItemPostMagazineDiscoverySponsor}) {
      ${StyledContentItemPostMagazineDiscovery} {
        :nth-child(n + 5) {
          display: none;
        }
      }
    }
  }
`;

export const Publication = styled.div`
  align-self: center;

  color: #666666;
  font-weight: 400;
  font-size: 11px;
  font-style: italic;
  line-height: 12.89px;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-self: flex-start;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  inline-size: 100%;
  ${props => props.theme.breakpoints.only("tablet")} {
    inline-size: unset;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  inline-size: 100%;

  ${props => props.theme.breakpoints.only("tablet")} {
    padding-block-start: 24px;

    border-block-start: 1px solid rgba(0, 0, 0, 0.2);
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 8px;
  }
`;
