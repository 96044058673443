/**
 * @generated SignedSource<<b8abfbbc723cfac51284c844d69ecd8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type chinaFutureTechDiscoveryWidgetQuery$data = {
  readonly chinaFutureTechQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetQueue">;
  } | null | undefined;
  readonly chinaFutureTechSection: {
    readonly name: string;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetSection" | "sectionTermLogoSection">;
  };
  readonly " $fragmentType": "chinaFutureTechDiscoveryWidgetQuery";
};
export type chinaFutureTechDiscoveryWidgetQuery$key = {
  readonly " $data"?: chinaFutureTechDiscoveryWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"chinaFutureTechDiscoveryWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "chinaFutureTechDiscoveryWidgetQuery",
  "selections": [
    {
      "alias": "chinaFutureTechSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "519735"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetSection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "sectionTermLogoSection"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"519735\"})"
    },
    {
      "alias": "chinaFutureTechQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_519735"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetQueue"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_top_519735\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ccadcd64a414d43e0dee0dc99c7dee67";

export default node;
