import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { StyledArticleComment } from "scmp-app/components/content/content-action-bar/styles";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { CommentWidgetPosition } from "scmp-app/components/tracking/google-analytics-4/types";
import type { postMagazineDiscoveryItemContent$key } from "scmp-app/queries/__generated__/postMagazineDiscoveryItemContent.graphql";

import {
  ActionBar,
  Container,
  CoverImage,
  Headline,
  ReadingTime,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
  Summary,
} from "./styles";

export type Props = {
  reference: postMagazineDiscoveryItemContent$key;
  skipHighlight?: boolean;
  trackingPosition?: CommentWidgetPosition;
  withSummary?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onClick,
      reference: reference_,
      skipHighlight = false,
      trackingPosition = "rail",
      withSummary = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment postMagazineDiscoveryItemContent on Content {
          entityId
          urlAlias
          ...articleCommentArticle
          ...entityLink
          ...helpersCheckHasLiveTagArticle
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withSummary: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withVideoDurationOverlay: true
              withReadingTime: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <StyledCoverEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
            })}
            {providers.video?.durationOverlay({
              iconSize: "hidden",
              variant: "homepage",
            })}
          </CoverImage>
        </StyledCoverEntityLink>
        <StyledHeadlineEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <Headline>
            {providers.headline({
              hideTooltip: true,
              preferSocialHeadline: true,
              skipHighlight,
            })}
          </Headline>
          {withSummary && (
            <Summary>
              {providers.summary({
                isTextOverflowEllipsis: true,
              })}
            </Summary>
          )}
        </StyledHeadlineEntityLink>
        <ActionBar>
          <ReadingTime>
            {providers.sponsorTag()}
            {providers.readingTime()}
          </ReadingTime>
          <StyledArticleComment
            $variant="reverse"
            reference={content}
            trackingPosition={trackingPosition}
          />
        </ActionBar>
      </Container>
    );
  },
);

Component.displayName = "ContentItemPostMagazineDiscovery";

export const ContentItemPostMagazineDiscovery = withHighlightedHeadline(Component);
