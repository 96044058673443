/**
 * @generated SignedSource<<1bbaff75528b61da41b61bee7aac0c19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleVideoWidgetQuery$data = {
  readonly styleVideoSectionPicksQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: "Video";
          readonly duration: string | null | undefined;
          readonly entityId: string;
          readonly headline: string | null | undefined;
          readonly image: {
            readonly style: {
              readonly url: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly socialHeadline: string | null | undefined;
          readonly videoSource: ReadonlyArray<{
            readonly id: string | null | undefined;
            readonly type: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionStyleVideoWidgetQuery";
};
export type sectionStyleVideoWidgetQuery$key = {
  readonly " $data"?: sectionStyleVideoWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleVideoWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleVideoWidgetQuery",
  "selections": [
    {
      "alias": "styleVideoSectionPicksQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "video_section_picks_323289"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "headline",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "socialHeadline",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VideoSource",
                          "kind": "LinkedField",
                          "name": "videoSource",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "filter",
                              "value": {
                                "type": "MAIN_IMAGE"
                              }
                            }
                          ],
                          "concreteType": "Image",
                          "kind": "LinkedField",
                          "name": "image",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "filter",
                                  "value": {
                                    "style": "wide_landscape"
                                  }
                                }
                              ],
                              "concreteType": "ImageStyle",
                              "kind": "LinkedField",
                              "name": "style",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "style(filter:{\"style\":\"wide_landscape\"})"
                            }
                          ],
                          "storageKey": "image(filter:{\"type\":\"MAIN_IMAGE\"})"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "duration",
                          "storageKey": null
                        }
                      ],
                      "type": "Video",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:1)"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"video_section_picks_323289\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2f6fe4603d81c0648cb0ae7ef478f08e";

export default node;
