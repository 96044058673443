import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.async-iterator.for-each.js";
import "core-js/modules/esnext.iterator.for-each.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_c2yczioqaaww5hjkhfaojzr7cu/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/esnext.json.parse.js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import qs from "qs";
import { config } from "shared/data";
import { SCMPAccessTokenExpireCookiesKey, SCMPPianoAccessTokenCookiesKey, SCMPUserCookiesKey, SCMPUserLoginTypeCookiesKey } from "./consts";
export const getAccountState = () => {
  const state = {
    accessTokenExpiryTime: (() => {
      const raw = Cookies.get(SCMPAccessTokenExpireCookiesKey);
      return raw ? Number.parseInt(raw) : null;
    })(),
    loginType: Cookies.get(SCMPUserLoginTypeCookiesKey),
    pianoAccessToken: Cookies.get(SCMPPianoAccessTokenCookiesKey),
    pianoUser: (() => {
      const raw = Cookies.get(SCMPPianoAccessTokenCookiesKey);
      return raw ? jwtDecode(raw) : null;
    })(),
    user: (() => {
      const raw = Cookies.get(SCMPUserCookiesKey);
      if (!raw) return null;
      const parsed = JSON.parse(raw);
      return _objectSpread(_objectSpread({}, parsed), {}, {
        created: Number.parseInt(`${parsed.created}`, 10),
        crs: Number.parseInt(`${parsed.crs}`, 10)
      });
    })()
  };
  const isLoggedIn = !!state.user;
  return _objectSpread(_objectSpread({}, state), {}, {
    isLoggedIn
  });
};
export function getLoginUrl(destination) {
  const host = location.origin;
  return `${config.account.scmpAccountHost}/login?callback_url_signin=${encodeURIComponent(`${host}/centralize/signin`)}&callback_url_register=${encodeURIComponent(`${host}/centralize/register`)}&destination=${encodeURIComponent(destination ?? window.location.href)}`;
}
export function getGoogleLoginUrl(parameters) {
  const host = location.origin;
  const query = qs.stringify(parameters);
  return `${config.account.scmpAccountHost}/login/google?callback=${encodeURIComponent(`${host}/centralize/signin`)}&destination=${encodeURIComponent(`${window.location.href}?${query}`)}&${query}`;
}
export const maskValue = value => [value.slice(0, 2), "*".repeat(value.slice(2, -5).length), value.slice(-5)].join("");