import styled from "@emotion/styled";
import { fontBodoni, fontIndivisibleVariable } from "@product/scmp-sdk";

import { Container as ArticleCommentContainer } from "scmp-app/components/article/article-comment/styles";
import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import {
  Headline as ContentHeadline,
  SponsorHeadline,
} from "scmp-app/components/content/content-headline/styles";
import { Container as ReadingTimeContainer } from "scmp-app/components/content/content-reading-time/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { SponsorTagContainer } from "scmp-app/components/sponsor-tag/styles";

export const Container = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  color: #111111;
`;

export const StyledHeadlineEntityLink = styled(StyledEntityLink)``;

export const Header = styled.div`
  :empty {
    margin-block-end: 0;
  }
`;

export const Headline = styled.div`
  ${ContentHeadline}, ${SponsorHeadline}, ${Flag} {
    color: #111111;
    font-weight: 400;
    font-size: 20px;
    font-family: ${fontBodoni};
    font-style: normal;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
  }
`;

export const Summary = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  line-height: 18.2px;

  &:empty {
    margin: 0;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  display: block;

  margin-block-end: 16px;

  aspect-ratio: 260 / 184;
`;

export const CoverImage = styled.div`
  inline-size: 100%;
  block-size: 100%;
  aspect-ratio: 260 / 184;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;

  margin-block-start: 14px;

  ${ArticleCommentContainer} {
    color: #111111;
  }
`;

export const ReadingTime = styled.div`
  ${ReadingTimeContainer} {
    align-items: center;

    span {
      color: #111111;
      font-weight: 450;
      font-size: 12px;
      font-family: ${fontIndivisibleVariable};
      font-variation-settings: "wght" 450;
    }

    strong {
      font-weight: 400;
      font-size: 12px;
      font-family: ${fontIndivisibleVariable};
      letter-spacing: -0.24px;
      text-transform: uppercase;
      font-variation-settings: "wght" 700;
    }
  }

  ${SponsorTagContainer}+${ReadingTimeContainer} {
    display: none;
  }
`;
