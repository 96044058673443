/**
 * @generated SignedSource<<76d96909361eee9753ee99761e602b41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleSectionSection$data = {
  readonly images: ReadonlyArray<{
    readonly url: string;
  } | null | undefined> | null | undefined;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  readonly " $fragmentType": "sectionStyleSectionSection";
};
export type sectionStyleSectionSection$key = {
  readonly " $data"?: sectionStyleSectionSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleSectionSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleSectionSection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "type": {
              "values": [
                "logo"
              ]
            }
          }
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "images(filter:{\"type\":{\"values\":[\"logo\"]}})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "bbb0595228dc02dfeb9742fd5c64447f";

export default node;
