import styled from "@emotion/styled";
import { SwiperSlide } from "swiper/react";

import { Swiper } from "scmp-app/components/swiper";

import IconArrowNext from "./icon-arrow-next.svg?url";
import IconArrowPrevious from "./icon-arrow-previous.svg?url";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const SwiperContainer = styled.div`
  inline-size: 100vw;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 100%;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    max-inline-size: 100vw;
    padding-block: 0 0;

    .swiper-button-prev {
      background-image: url(${IconArrowPrevious});
    }
    .swiper-button-next {
      direction: rtl;

      background-image: url(${IconArrowNext});
    }

    .swiper-button-next,
    .swiper-button-prev {
      inset-inline-start: 20px;

      inline-size: 40px;
      block-size: 40px;

      background-color: #ffffff;
      background-size: 13px;

      opacity: 1;

      user-select: none;

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    ${props => props.theme.breakpoints.up("tablet")} {
      padding-inline: 24px;

      .swiper-button-next,
      .swiper-button-prev {
        inset-inline-start: 10px;

        inline-size: 60px;
        block-size: 60px;

        border: 1px solid #222222;

        background-size: 20px;
      }
    }

    ${props => props.theme.breakpoints.up("desktop")} {
      padding-inline: 40px;

      .swiper-button-next,
      .swiper-button-prev {
        inset-inline-start: 0;

        inline-size: 80px;
        block-size: 80px;

        background-size: 26px;
      }
    }

    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      max-inline-size: 1268px;
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  &.swiper-slide {
    inline-size: 300px;

    :first-child {
      inline-size: 320px;

      > * {
        padding-inline-start: 20px;
      }
    }

    :last-child {
      inline-size: 320px;

      > * {
        padding-inline-end: 20px;
      }
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    &.swiper-slide {
      inline-size: 375px;

      :first-child {
        inline-size: 375px;

        > * {
          padding-inline-start: 0;
        }
      }

      :last-child {
        inline-size: 375px;

        > * {
          padding-inline-end: 0;
        }
      }
    }
  }
`;
