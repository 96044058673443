import { FollowType, notEmpty, PageType } from "@product/scmp-sdk";
import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { checkHasLiveTag } from "scmp-app/components/article/article-render/helpers";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { sectionTopLeadSecondaryContentItemContent$key } from "scmp-app/queries/__generated__/sectionTopLeadSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Header,
  Headline,
  LeadingImage,
  LiveTagContainer,
  Primary,
  SectionContainer,
  StyledContentCoverImage,
  StyledEntityLink,
  StyledHomeFollowButton,
  Summary,
  Topic,
  TopicContainer,
  TopicLabel,
} from "./styles";

export type Props = {
  preferTopicLabel?: boolean;
  reference: sectionTopLeadSecondaryContentItemContent$key;
  withBookmark?: boolean;
  withComment?: boolean;
  withLiveTag?: boolean;
  withSection?: boolean;
  withTopic?: boolean;
  withVideoOverlay?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      customQueryParameters,
      onClick,
      preferTopicLabel = false,
      reference: reference_,
      withBookmark = false,
      withComment = false,
      withLiveTag = false,
      withSection = false,
      withTopic = false,
      withVideoOverlay = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment sectionTopLeadSecondaryContentItemContent on Content {
          entityId
          urlAlias
          topics {
            ...followButtonBase
          }
          ...entityLink
          ...contentCoverImageContent @arguments(withSize1200x800: true, withSize540x360: true)
          ...helpersUseContentSponsorContent
          ...helpersCheckHasLiveTagArticle
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withTopicLink: true
              withLiveTag: true
              withHeadline: true
              withSummary: true
              withActionBar: true
              withSponsorTag: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, { type: "main" });

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);
    const topic = first(content?.topics ?? []);
    const currentPageType = useCurrentPageType();

    const hasLiveTag = checkHasLiveTag(content);
    const isShowLiveTag = hasLiveTag && withLiveTag;

    const renderHeader = () => {
      switch (true) {
        case isShowLiveTag:
          return <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>;
        case withTopic:
          return preferTopicLabel ? (
            <TopicLabel>{providers.topicLink?.({ preferShortName: true })}</TopicLabel>
          ) : (
            <TopicContainer>
              <Topic>{providers.topicLink?.()}</Topic>
              {topic && (
                <StyledHomeFollowButton
                  buttonVariant="square"
                  reference={topic}
                  source={currentPageType === PageType.Section ? "Section" : "HP Section Block"}
                  type={FollowType.Topic}
                />
              )}
            </TopicContainer>
          );
        case withSection:
          return <SectionContainer> {providers.sections()}</SectionContainer>;
        default:
          return null;
      }
    };

    return (
      <Container className={className} ref={reference}>
        <LeadingImage>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <StyledContentCoverImage
              reference={content}
              responsiveVariants={{
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              }}
            />
            {withVideoOverlay &&
              providers.video?.durationOverlay({
                iconSize: "large",
                variant: "homepage",
              })}
          </StyledEntityLink>
        </LeadingImage>
        <Primary>
          <Header>{renderHeader()}</Header>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            query={customQueryParameters}
            reference={content}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>
          {isSponsorArticle ? (
            providers.sponsorTag?.({ variant: "home" })
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                isBookmarkVisible: withBookmark ?? false,
                isCommentsVisible: withComment ?? false,
              })}
            </ActionBar>
          )}
        </Primary>
      </Container>
    );
  },
);

Component.displayName = "ContentItemSectionTopLeadSecondary";

export const ContentItemSectionTopLeadSecondary = withHighlightedHeadline(Component);
