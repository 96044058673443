import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { ContentItemMultimedia } from "scmp-app/components/content/content-item-render/variants/multimedia";

export const StyledContentItemHomeMultimedia = styled(ContentItemMultimedia)``;

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    align-items: flex-start;

    column-gap: 20px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  margin-block-end: 20px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const SectionArticleItems = styled.div`
  display: flex;
  flex-direction: column;

  ${Headline} {
    font-weight: 400;
    font-size: 15px;
    font-family: ${fontMerriweather};
    font-style: normal;
    line-height: 21px;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: 12px;

      font-size: 15px;
      line-height: 21px;
    }
  }

  ${CoverImage} {
    inline-size: 100%;
    block-size: 100%;
    aspect-ratio: 3/2;
  }

  ${ActionBar} {
    display: none;
  }

  ${StyledCoverEntityLink} {
    display: block;

    margin-block-start: 0;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: auto;
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 20px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    flex-direction: column;
    gap: 0;
  }
`;

export const WidgetContainer = styled.div`
  margin-block-start: 32px;
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid rgba(0, 0, 0, 0.2);
  ${props => props.theme.breakpoints.up("desktop")} {
    max-inline-size: 300px;
    margin-block-start: 0;
  }

  ${StyledContentItemHomeSecondary}:not(:first-child) {
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    margin-block-start: 20px;
    padding-block-start: 20px;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: 0;
      border-block-start: 0;

      padding-block-start: 0;
    }
    ${props => props.theme.breakpoints.up("desktop")} {
      margin-block-start: 20px;
      padding-block-start: 20px;
      border-block-start: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const WidgetTitle = styled.div`
  margin-block-end: 20px;

  color: rgb(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
  text-transform: uppercase;
`;
