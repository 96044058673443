import styled from "@emotion/styled";

import BackgroundPlusWidget from "./plus-widget-background.png";
import SvgPlusWidgetPlaceholderTablet from "./plus-widget-placeholder-tablet.svg";
import SvgPlusWidgetPlaceholder from "./plus-widget-placeholder.svg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  inline-size: 100%;
  max-inline-size: 727px;
  margin-inline: auto;
  padding-block: 24px;
  padding-inline: 24px;

  color: #ffffff;

  border-radius: 12px;

  background-image: url(${BackgroundPlusWidget.src});
  background-position: top right;
  background-size: cover;

  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    max-inline-size: 960px;
    padding-inline: 24px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    max-inline-size: 300px;
  }
`;

export const ContentWrapper = styled.div`
  max-inline-size: 360px;
  margin-inline: auto;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    max-inline-size: 656px;
  }
`;

export const PlusWidgetPlaceholder = styled(SvgPlusWidgetPlaceholder)`
  display: block;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    display: none;
  }
`;

export const PlusWidgetPlaceholderTablet = styled(SvgPlusWidgetPlaceholderTablet)`
  display: none;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    display: block;
  }
`;

export const Placeholder = () => (
  <>
    <PlusWidgetPlaceholder />
    <PlusWidgetPlaceholderTablet />
  </>
);
