import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import { forwardRef, useMemo, useState } from "react";
import type { ChildrenFnProps } from "react-photoswipe-gallery/dist/types";

import type { Props as BaseImageProps } from "scmp-app/components/common/base-image";
import { Spacer } from "scmp-app/components/common/spacer";
import { GalleryItem } from "scmp-app/components/image-gallery/gallery-item";

import type { StyledProps } from "./styles";
import {
  ArticleImageContainer,
  DescriptionContainer,
  ImageContainer,
  StyledIconClose,
  StyledIconTooltip,
  StyledImage,
} from "./styles";

export type Props = {
  className?: string;
  imageProps: BaseImageProps;
  skipGallery?: boolean;
  title?: string;
  toggleTitle?: boolean;
} & Partial<PropsFromStyledProps<StyledProps>>;

export const ArticleImage = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      imageProps,
      responsiveDescriptionVariants = { desktopUp: "show", mobileUp: "show", tabletUp: "show" },
      responsiveImageVariants = { desktopUp: "normal", mobileUp: "normal", tabletUp: "normal" },
      skipGallery = false,
      title,
      toggleTitle = false,
    },
    reference,
  ) => {
    const [toggle, setToggle] = useState(false);

    const isShowDescription = useMemo(
      () => (toggleTitle ? toggle : notEmpty(title)),
      [title, toggle, toggleTitle],
    );

    const handleRenderImage = ({ open, ref }: ChildrenFnProps<HTMLElement>) => {
      if (!skipGallery)
        return (
          <ImageContainer onClick={open}>
            <StyledImage
              $clickable
              ref={ref}
              {...imageProps}
              alt={findFirstNonEmptyString(imageProps.alt, title) || undefined}
            />
          </ImageContainer>
        );

      return (
        <ImageContainer>
          <StyledImage
            $clickable={false}
            {...imageProps}
            alt={findFirstNonEmptyString(imageProps.alt, title) || undefined}
          />
        </ImageContainer>
      );
    };

    return (
      <GalleryItem src={imageProps.src} title={title ?? ""}>
        {galleryProps => (
          <ArticleImageContainer
            $responsiveDescriptionVariants={responsiveDescriptionVariants}
            $responsiveImageVariants={responsiveImageVariants}
            $toggleTitle={toggleTitle}
            className={className}
            ref={reference}
          >
            {handleRenderImage(galleryProps)}

            {notEmpty(title) && toggleTitle && !toggle && (
              <StyledIconTooltip onClick={() => setToggle(true)} />
            )}

            {isShowDescription && (
              <DescriptionContainer>
                {title}
                <Spacer />
                {toggle && <StyledIconClose onClick={() => setToggle(false)} />}
              </DescriptionContainer>
            )}
          </ArticleImageContainer>
        )}
      </GalleryItem>
    );
  },
);

ArticleImage.displayName = "ArticleImage";
