import { atom } from "jotai";

import type { AdvertisementCampaignConfig } from "scmp-app/components/advertisement/campaign/hooks";
import { defaultAdvertisementCampaignConfig } from "scmp-app/components/advertisement/campaign/hooks";
import type { AdvertisementConfig } from "scmp-app/components/advertisement/hooks";
import { defaultAdvertisementConfig } from "scmp-app/components/advertisement/hooks";
import type { NavigatorInfo } from "scmp-app/lib/hooks";

export interface App {
  advertisementCampaignConfig: AdvertisementCampaignConfig;
  advertisementCampaignConfigLoaded?: boolean;
  advertisementConfig: AdvertisementConfig;
  advertisementConfigLoaded?: boolean;
  navigatorInfo?: NavigatorInfo;
  navigatorInfoLoaded?: boolean;
}

export const appAtom = atom<App>({
  advertisementCampaignConfig: defaultAdvertisementCampaignConfig,
  advertisementCampaignConfigLoaded: false,
  advertisementConfig: defaultAdvertisementConfig,
  advertisementConfigLoaded: false,
});
