import { useAsync, useMountEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";

import { section as sectionData } from "shared/data/section";
import { accountAtom } from "shared/lib/account";

import { fetchAiEngineData } from "scmp-app/lib/ai-engine/apis";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";

export const useFetchAiArticleWidgetArticles = (
  aiEngineDatatype = AiEngineDataType.Section,
  sectionIds: string[],
) => {
  const { user } = useAtomValue(accountAtom);
  const [{ result }, { execute }] = useAsync(async () => {
    if (aiEngineDatatype === AiEngineDataType.HomeRecommendedForYou) {
      const aiEngineData = await fetchAiEngineData<AiEngineDataType.HomeRecommendedForYou>({
        type: AiEngineDataType.HomeRecommendedForYou,
        userUuid: user?.uuid,
      });
      return aiEngineData;
    }
    // Default to AiEngineDataType.Section
    const aiEngineData = await fetchAiEngineData<AiEngineDataType.Section>({
      excludedSections: [sectionData.posties.name, sectionData.yp.name],
      sectionEntityIds: sectionIds,
      type: AiEngineDataType.Section,
      userUuid: user?.uuid,
    });
    return aiEngineData;
  });

  useMountEffect(() => {
    void execute();
  });

  return {
    aiData: result?.data,
  };
};
