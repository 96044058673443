import React from "react";
import type { PreloadedQuery } from "react-relay/hooks";
import { graphql, usePreloadedQuery } from "react-relay/hooks";

import type { sectionStyleMainHeadNavQuery } from "scmp-app/queries/__generated__/sectionStyleMainHeadNavQuery.graphql";

import { StyledEntityLink, StyledSectionStyleHeaderMenuItem } from "./styles";

export type Props = {
  queryReference: PreloadedQuery<sectionStyleMainHeadNavQuery>;
};

export const SectionStyleMainHeadNav: React.FunctionComponent<Props> = ({ queryReference }) => {
  const queryResponse = usePreloadedQuery<sectionStyleMainHeadNavQuery>(query, queryReference);

  return (
    <>
      {queryResponse.styleMenu?.items?.edges.map((edge, index) => (
        <StyledEntityLink key={index} reference={edge.node}>
          <StyledSectionStyleHeaderMenuItem reference={edge.node} />
        </StyledEntityLink>
      ))}
    </>
  );
};

SectionStyleMainHeadNav.displayName = "SectionStyleMainHeadNav";

export const query = graphql`
  query sectionStyleMainHeadNavQuery {
    styleMenu: queue(filter: { name: "scmp_pwa_oneline_menu_style" }) {
      items(first: 6) {
        edges {
          node {
            ... on Section {
              ...entityLink
              ...sectionStyleHeaderMenuItem
            }
          }
        }
      }
    }
  }
`;
