import styled from "@emotion/styled";

import { SCMPYoutubeVideo } from "scmp-app/components/scmp-youtube-video";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  display: grid;
  grid-template:
    "video" max-content
    "headline" max-content;
  align-items: center;

  inline-size: 100%;
`;

export const StyledSCMPYoutubeVideo = styled(SCMPYoutubeVideo)`
  grid-area: video;

  margin-inline: auto;
  ${props => props.theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
  }
`;
