/**
 * @generated SignedSource<<3fc6a80db445592d22f91bc21cdefeb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type magazinesStyleLeadContentItemContent$data = {
  readonly authors: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"authorNameAuthor">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "helpersIsArticleVideoAsHeroArticle" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "magazinesStyleLeadContentItemContent";
};
export type magazinesStyleLeadContentItemContent$key = {
  readonly " $data"?: magazinesStyleLeadContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleLeadContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "magazinesStyleLeadContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "authorNameAuthor"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersIsArticleVideoAsHeroArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormattedValue",
              "kind": "LinkedField",
              "name": "multimediaEmbed",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1280x720",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize500x500",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverVideo",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withVideoDurationOverlay",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "0e98a8053ce3954b217af45038626761";

export default node;
