import dynamic from "next/dynamic";

import type { Props as AppBarProps } from "scmp-app/components/app-bar";
import type { Props as HamburgerMenuProps } from "scmp-app/components/hamburger-menu";
import type { Props as NewsTickerProps } from "scmp-app/components/news-ticker";
import type { Props as PaywallHookRunnerProps } from "scmp-app/pages/plus/learnmore/paywall-hook-runner";

export const DynamicAppBar = dynamic<AppBarProps>(() =>
  import("scmp-app/components/app-bar").then(module => module.AppBar),
);

export const DynamicLoginDialog = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/login-dialog").then(module => module.LoginDialog),
);

export const DynamicSimpleHooksRunner = dynamic(() =>
  import("scmp-app/components/simple-hooks-runner").then(module => module.SimpleHooksRunner),
);

export const DynamicNewsTicker = dynamic<NewsTickerProps>(() =>
  import("scmp-app/components/news-ticker").then(module => module.NewsTicker),
);

export const DynamicPopupContainer = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/popup-container").then(module => module.PopupContainer),
);

export const DynamicHamburgerMenu = dynamic<HamburgerMenuProps>(() =>
  import("scmp-app/components/hamburger-menu").then(module => module.HamburgerMenu),
);

export const DynamicPlusPaywallHookRunner = dynamic<PaywallHookRunnerProps>(() =>
  import("scmp-app/pages/plus/learnmore/paywall-hook-runner").then(
    module => module.PlusPaywallHookRunner,
  ),
);
