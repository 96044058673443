import { forwardRef, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { Targeting } from "scmp-app/components/advertisement/ad-slots/types";
import { isArticleVideoAsHero } from "scmp-app/components/content/content-cover-video/helpers";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { EntityLink } from "scmp-app/components/entity-link";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { sanitizeReadonlyArray } from "scmp-app/lib/utils";
import type { magazinesStyleLeadContentItemContent$key } from "scmp-app/queries/__generated__/magazinesStyleLeadContentItemContent.graphql";

import {
  AuthorNameContainer,
  AuthorNameWrapper,
  Container,
  Cover,
  CoverContainer,
  Headline,
  StoryLabel,
  StyledAuthorName,
  StyledEntityLink,
} from "./styles";

export type Props = {
  advertisement?: {
    targeting: Targeting;
    zone: string;
  };
  reference: magazinesStyleLeadContentItemContent$key;
  withLeadingCoverVideo?: boolean;
} & ContentItemRenderVariantProps;

export const ContentItemMagazinesStyleLead = forwardRef<HTMLDivElement, Props>(
  ({ advertisement, className, reference, withLeadingCoverVideo }, elementReference) => {
    const content = useFragment(
      graphql`
        fragment magazinesStyleLeadContentItemContent on Content {
          authors {
            ...authorNameAuthor
          }
          ...entityLink
          ...helpersIsArticleVideoAsHeroArticle
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverVideo: true
              withCoverImage: true
              withCoverImageSize1280x720: true
              withCoverImageSize500x500: true
              withVideoDurationOverlay: true
              withHeadline: true
            )
        }
      `,
      reference,
    );
    const providers = useContentItemProviders(content);

    const cover = (
      <CoverContainer>
        {isArticleVideoAsHero(content) && withLeadingCoverVideo ? (
          providers.coverVideo({
            advertising: {
              ...advertisement,
              instream: "instream3",
            },
          })
        ) : (
          <EntityLink reference={content}>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1280x720",
                mobileUp: "size500x500",
                tabletUp: "size1280x720", // TODO: update variant when image-style available
              },
            })}
            {providers.video?.durationOverlay({
              responsiveVariants: { desktopUp: "large", mobileUp: "large", tabletUp: "large" },
            })}
          </EntityLink>
        )}
      </CoverContainer>
    );
    const headline = (
      <StyledEntityLink reference={content}>
        {providers.headline({ preferSocialHeadline: true, variant: MagazinesStyleVariant })}
      </StyledEntityLink>
    );

    const authors = useMemo(() => sanitizeReadonlyArray(content.authors), [content.authors]);
    const hasAuthor = authors.length > 0;
    const isMultipleAuthors = authors.length > 1;

    return (
      <Container className={className} ref={elementReference}>
        <Cover>{cover}</Cover>
        <Headline>{headline}</Headline>
        {hasAuthor && (
          <AuthorNameContainer $isMultipleAuthors={isMultipleAuthors}>
            <StoryLabel>story</StoryLabel>
            <span>
              {authors.map((author, index) => (
                <AuthorNameWrapper key={index}>
                  <StyledAuthorName reference={author} />
                </AuthorNameWrapper>
              ))}
            </span>
          </AuthorNameContainer>
        )}
      </Container>
    );
  },
);

ContentItemMagazinesStyleLead.displayName = "ContentItemMagazinesStyleLead";
