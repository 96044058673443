import { config } from "shared/data";

export const getRssFeedLink = (termId?: string) => {
  const defaultRssFeedLink = {
    href: `https://${config.domain.scmp}/rss/feed`,
    rel: "alternate",
    type: "application/rss+xml",
  };
  if (termId) {
    return {
      ...defaultRssFeedLink,
      href: `https://${config.domain.scmp}/rss/${termId}/feed`,
    };
  }
  return defaultRssFeedLink;
};
