import { useCallback } from "react";

import type { AdUnit } from "scmp-app/components/advertisement/ad-slots/types";

export const useFetchBids = (slot: googletag.Slot | null) => {
  const handleFetchBids = useCallback(
    () =>
      new Promise(resolve => {
        if (!slot) return resolve(false);
        pbjs?.que?.push(() => {
          void pbjs?.rp?.requestBids({
            callback: () => resolve(true),
            gptSlotObjects: [slot],
          });
        });
      }),
    [slot],
  );

  return {
    handleFetchBids,
  };
};

export function useTargeting(adUnit: AdUnit, customizedAdUnitTargetingTargeting?: string) {
  return {
    adslot: customizedAdUnitTargetingTargeting ?? adUnitTargetingMapping[adUnit],
  };
}

export type MagniteTargetingKeys = keyof ReturnType<typeof useTargeting>;

// Cspell:ignore dnative storycard
const adUnitTargetingMapping: Record<AdUnit, string> = {
  d_banner1: "Leaderboard",
  d_banner2: "leaderboard2c",
  d_banner2a: "leaderboard2a",
  d_banner2b: "leaderboard2d",
  d_banner3: "Leaderboard_3",
  d_banner4: "leaderboard4",
  d_banner5: "leaderboard7",
  d_lrec1: "LREC1",
  d_lrec2: "LREC2",
  d_lrec3: "LREC3",
  d_lrec3a: "LREC3",
  d_lrec3b: "LREC3b",
  d_lrec4: "LREC4",
  d_lrec4a: "LREC4",
  d_lrec4b: "LREC4",
  d_lrec4d: "LREC4",
  d_native1: "dnative-1",
  d_native2: "dnative-2",
  d_native2a: "dnative-3",
  d_native2b: "dnative-4",
  d_native2c: "dnative-6",
  d_native3b: "dnative-5",
  d_native5: "native5",
  d_oop3: "storycard",
  d_sky1b: "Sky1c",
  hpto1: "hpto1",
  m_banner1: "Leaderboard_Mobile",
  m_banner2: "M_leaderboard2",
  m_banner3: "M_leaderboard3",
  m_banner3a: "M_leaderboard3A",
  m_banner4: "M_leaderboard4",
  m_banner5: "leaderboard7",
  m_lrec1: "LREC1_mobile",
  m_lrec2: "LREC2_mobile",
  m_lrec3: "LREC3_mobile",
  m_lrec3a: "LREC3a",
  m_lrec3c: "LREC3_mobile",
  m_lrec3g: "LREC3_mobile",
  m_lrec3h: "LREC3_mobile",
  m_lrec4: "LREC4_mobile",
  m_lrec4a: "LREC4a_mobile",
  m_lrec4b: "LREC4b_mobile",
  m_lrec4d: "LREC4d_mobile",
  m_lrec4e: "LREC4e_mobile",
  m_lrec4f: "LREC4f_mobile",
  m_native2: "dnative-2",
  m_native2a: "dnative-3",
  m_native2b: "dnative-4",
  m_native2c: "dnative-6",
  m_native5: "native5",
  m_oop3: "storycard",
  native4: "native4",
  native5: "native5",
  outstream1: "outstream1",
  "privacypolicy/privacypolicypixel": "privacypolicy/privacypolicypixel",
};

declare global {
  // Manually for used functions. Referencing https://docs.prebid.org/dev-docs/publisher-api-reference.html
  namespace pbjs {
    const que: (() => void)[];
    const rp: {
      requestBids: (request: {
        adUnitCodes?: string[];
        callback?: () => void;
        gptSlotObjects?: googletag.Slot[];
      }) => Promise<BidsResponse>;
    };
    type BidsResponse = { auctionId: string; bids: Record<string, unknown>; timedOut: boolean };
  }
}
