import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { data as appData } from "shared/data";

import { useSectionContext } from "scmp-app/components/section/contexts";
import type { sectionStyleVideoWidgetQuery$key } from "scmp-app/queries/__generated__/sectionStyleVideoWidgetQuery.graphql";

import { Container, StyledSCMPYoutubeVideo } from "./styles";

export type Props = {
  reference: sectionStyleVideoWidgetQuery$key;
};

export const SectionStyleVideoWidget: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const { advertisement: baseAdvertisement } = useSectionContext();
  const data = useFragment(
    graphql`
      fragment sectionStyleVideoWidgetQuery on Query {
        styleVideoSectionPicksQueue: queue(filter: { name: "video_section_picks_323289" }) {
          items(first: 1) {
            edges {
              node {
                __typename
                ... on Video {
                  entityId
                  headline
                  socialHeadline
                  videoSource {
                    id
                    type
                  }
                  image(filter: { type: MAIN_IMAGE }) {
                    style(filter: { style: "wide_landscape" }) {
                      url
                    }
                  }
                  duration
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const video = data.styleVideoSectionPicksQueue?.items?.edges[0]?.node;
  if (!video || video.__typename !== "Video") return null;

  const videoSource = first(video.videoSource?.filter(item => item?.type === "youtube"));
  const youtubeVideoId = videoSource?.id;
  if (!youtubeVideoId) return null;

  const adInfo: ADInfo = {
    targeting: {
      ...baseAdvertisement.targeting,
      adslot: "Instream",
    },
    zone: baseAdvertisement.zone + "/instream1",
  };

  const ga4Position = "footer_widget";

  return (
    <Container>
      <StyledSCMPYoutubeVideo
        adInfo={adInfo}
        duration={video.duration ?? undefined}
        enableAutoPlay={true}
        entityId={video.entityId}
        gaData={{ ga4Position }}
        imageSource={video.image?.style?.url ?? undefined}
        index={Number.parseInt(video.entityId)}
        isEnabledImaManager={true}
        isHeroVideo={false}
        relatedChannels={[appData.youtube.styleChannelId]}
        title={video.socialHeadline ?? video.headline ?? ""}
        withFooter={{ variant: "magazines-style", withIcon: false }}
        youtubeVideoId={youtubeVideoId}
      />
    </Container>
  );
};

SectionStyleVideoWidget.displayName = "SectionStyleVideoWidget";
