import { theme } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemMagazinesStyleSpotlight } from "scmp-app/components/content/content-item-render/variants/magazines-style-spotlight";
import type { sectionStyleSpotlightQuery$key } from "scmp-app/queries/__generated__/sectionStyleSpotlightQuery.graphql";

import {
  Container,
  Header,
  StyledHeaderLogo,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
} from "./styles";

export type Props = {
  reference: sectionStyleSpotlightQuery$key;
};

export const SectionStyleSpotlight: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment sectionStyleSpotlightQuery on Query {
        spotlight: queue(filter: { entityId: "4335" }) {
          items {
            edges {
              node {
                ... on BaseWithApplicationAndUrlAlias {
                  entityId
                }
                ...magazinesStyleSpotlightContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const edges = data.spotlight?.items?.edges;
  if (!edges?.length) return null;

  const breakpoints = {
    0: {
      spaceBetween: 16,
    },
    [theme.breakpoints.values.tablet]: {
      spaceBetween: 24,
    },
  };

  return (
    <Container>
      <SwiperContainer>
        <Header>
          <StyledHeaderLogo />
        </Header>
        <StyledSwiper
          breakpoints={breakpoints}
          cssMode={false}
          freeMode={true}
          navigation={false}
          slidesPerView="auto"
        >
          {edges.map(({ node }) => (
            <StyledSwiperSlide key={node.entityId}>
              <ContentItemMagazinesStyleSpotlight reference={node} />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    </Container>
  );
};

SectionStyleSpotlight.displayName = "SectionStyleSpotlight";
