import { NoSsr } from "@mui/base";
import { notEmpty, theme } from "@product/scmp-sdk";
import type { ComponentProps, FunctionComponent } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { AppFooter } from "scmp-app/components/app-footer";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { PostMagazineHeader } from "scmp-app/components/post-magazine/header";
import { PostMagazineLeadStory } from "scmp-app/components/post-magazine/lead-story";
import { PostMagazineNewsletter } from "scmp-app/components/post-magazine/post-magazine-newsletter";
import { PostMagazineRecommended } from "scmp-app/components/post-magazine/recommended";
import { PostMagazineSectionTop } from "scmp-app/components/post-magazine/section-top";
import { SectionTopWidgetOne } from "scmp-app/components/post-magazine/section-top-widget";
import {
  getDefaultAdSlotProps,
  getWidgetsMap,
} from "scmp-app/components/post-magazine/section-top/helpers";
import { useSectionContext } from "scmp-app/components/section/contexts";
import type { sectionPostMagazineQuery$key } from "scmp-app/queries/__generated__/sectionPostMagazineQuery.graphql";

import {
  AdContainer,
  AdSlotContainer,
  Container,
  Header,
  LeadStory,
  Newsletter,
  Recommended,
  SecondAds,
  SectionContainer,
  Subsection1,
  ThirdAds,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  randomSeed?: string;
  reference: sectionPostMagazineQuery$key;
};

export const SectionPostMag: FunctionComponent<Props> = ({
  className,
  randomSeed,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment sectionPostMagazineQuery on Query
      @argumentDefinitions(
        articlesQueueName: { type: "String!" }
        entityId: { type: "String!" }
        sponsorQueueName: { type: "String!" }
      ) {
        ...headerPostMagazineQuery
        ...postMagazineNewsletter
        section: section(filter: { entityId: $entityId }) {
          entityId
          advertZone(version: 2)
        }
        leadStoryPostMagQueue: queue(filter: { name: $articlesQueueName }) {
          items(first: 6) {
            edges {
              ...onePostMagSectionTopWidgetArticles
              ...twoPostMagSectionTopWidgetArticles
              ...threePostMagSectionTopWidgetArticles
              node {
                ...leadStoryPostMagazineContent
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
        sponsorQueue: queue(filter: { name: $sponsorQueueName }) {
          items(first: 6) {
            edges {
              ...onePostMagSectionTopWidgetSponsorQueueItemsEdge
            }
          }
        }
      }
    `,
    reference_,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  const sectionId = data.section.entityId;
  const adZone = data?.section?.advertZone ?? "default";
  const [leadStory, ...latestArticles] =
    data?.leadStoryPostMagQueue?.items?.edges?.filter(item => notEmpty(item)) ?? [];
  const excludedArticleIds =
    data?.leadStoryPostMagQueue?.items?.edges.map(({ node }) => node?.entityId ?? "") ?? [];

  const largeStyles = {
    $height: 250,
    $padding: 20,
  };
  const smallStyles = {
    $height: 100,
    $padding: 10,
  };

  const bindAdSlotContainer = {
    $responsiveVariants: {
      desktopUp: largeStyles,
      mobileUp: smallStyles,
      tabletUp: smallStyles,
    },
  };

  const defaultAdSlotProps = getDefaultAdSlotProps(sectionId, adZone);
  const widgetsMap = getWidgetsMap(defaultAdSlotProps);

  return (
    <Container className={className}>
      <Wrapper>
        <AdSlotContainer {...bindAdSlotContainer}>
          <NoSsr>
            <AdSlot
              adUnit="d_banner1"
              breakpoint={theme.breakpoints.up("desktop")}
              sizes={[[970, 250], [728, 90], [970, 90], "fluid"]}
              targeting={{
                ...baseAdvertisement.targeting,
                scsid: [sectionId],
              }}
              withBackground
              zone={baseAdvertisement.zone}
            />
            <AdSlot
              adUnit="m_banner3"
              breakpoint={theme.breakpoints.down("desktop")}
              sizes={[
                [300, 100],
                [320, 100],
                [300, 50],
                [320, 50],
              ]}
              targeting={{
                ...baseAdvertisement.targeting,
                scsid: [sectionId],
              }}
              withBackground
              zone={baseAdvertisement.zone}
            />
          </NoSsr>
        </AdSlotContainer>
        <Header>
          <PostMagazineHeader isMainSection={true} reference={data} />
        </Header>
        <LeadStory>
          {leadStory.node && (
            <PostMagazineLeadStory
              adZone={adZone}
              reference={leadStory.node}
              sectionId={sectionId}
            />
          )}
        </LeadStory>
        <Subsection1>
          <SectionTopWidgetOne
            randomSeed={randomSeed}
            reference={latestArticles}
            sponsorReference={data.sponsorQueue?.items?.edges ?? []}
            {...(widgetsMap[0].props as Partial<ComponentProps<typeof SectionTopWidgetOne>>)}
          />
        </Subsection1>
        <ErrorBoundary fallback={<AppFooter variant="post-magazine" />}>
          <ClientSideSuspense>
            <SectionContainer>
              <Recommended>
                <ClientSideSuspense>
                  <PostMagazineRecommended />
                </ClientSideSuspense>
              </Recommended>
              <PostMagazineSectionTop
                adZone={adZone}
                excludedArticleIds={excludedArticleIds}
                sectionId={sectionId}
              />
              <SecondAds>
                <AdContainer>
                  <AdSlot
                    adUnit="d_banner2"
                    breakpoint={theme.breakpoints.up("desktop")}
                    sizes={[[970, 250], [300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withLabel
                    zone={adZone}
                  />
                  <AdSlot
                    adUnit="m_banner4"
                    breakpoint={theme.breakpoints.only("tablet")}
                    sizes={[[300, 100], [320, 100], [300, 50], [320, 50], [300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withLabel
                    zone={adZone}
                  />
                  <AdSlot
                    adUnit="m_lrec2"
                    breakpoint={theme.breakpoints.only("mobile")}
                    sizes={[[300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withBackground
                    withLabel
                    zone={adZone}
                  />
                </AdContainer>
              </SecondAds>
              <ThirdAds>
                <AdContainer>
                  <AdSlot
                    adUnit="d_banner4"
                    breakpoint={theme.breakpoints.up("desktop")}
                    sizes={[[970, 250], [300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withLabel
                    zone={adZone}
                  />
                  <AdSlot
                    adUnit="m_banner4"
                    breakpoint={theme.breakpoints.only("tablet")}
                    sizes={[[300, 100], [320, 100], [300, 50], [320, 50], [300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withLabel
                    zone={adZone}
                  />
                  <AdSlot
                    adUnit="m_banner4"
                    breakpoint={theme.breakpoints.only("mobile")}
                    sizes={[[300, 50], [320, 50], [300, 100], [320, 100], [300, 250], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                      scsid: [sectionId],
                    }}
                    withBackground
                    withLabel
                    zone={adZone}
                  />
                </AdContainer>
              </ThirdAds>
            </SectionContainer>
            <Newsletter>
              <PostMagazineNewsletter reference={data} />
            </Newsletter>
            <AppFooter variant="post-magazine" />
          </ClientSideSuspense>
        </ErrorBoundary>
      </Wrapper>
    </Container>
  );
};

SectionPostMag.displayName = "SectionPostMag";
