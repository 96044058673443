import { theme } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemStyleFashionCarousel } from "scmp-app/components/content/content-item-render/variants/magazines-style-fashion-carousel";
import type { sectionStyleFashionCarouselQuery$key } from "scmp-app/queries/__generated__/sectionStyleFashionCarouselQuery.graphql";

import { Container, StyledSwiper, StyledSwiperSlide, SwiperContainer } from "./styles";

export type Props = {
  className?: string;
  reference: sectionStyleFashionCarouselQuery$key;
};

export const SectionStyleFashionCarousel: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment sectionStyleFashionCarouselQuery on Query {
        fashionCarousel: queue(filter: { name: "style_fashion_carousel" }) {
          items {
            edges {
              node {
                ... on BaseWithApplicationAndUrlAlias {
                  entityId
                }
                ...magazinesStyleFashionCarouselContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const edges = data.fashionCarousel?.items?.edges;
  if (!edges?.length) return null;

  const breakpoints = {
    0: {
      spaceBetween: 16,
    },
    [theme.breakpoints.values.tablet]: {
      spaceBetween: 29,
    },
  };

  return (
    <Container className={className}>
      <SwiperContainer>
        <StyledSwiper breakpoints={breakpoints} pagination={false} slidesPerView="auto">
          {edges.map(({ node }) => (
            <StyledSwiperSlide key={node.entityId}>
              <ContentItemStyleFashionCarousel
                reference={node}
                topicLinkVariant={{ type: "main" }}
              />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    </Container>
  );
};

SectionStyleFashionCarousel.displayName = "SectionStyleFashionCarousel";
