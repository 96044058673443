import { theme } from "@product/scmp-sdk";

import { section as sectionData } from "shared/data/section";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";

export const getDefaultSectionAdsConfigs = (sectionId: string, adZone = "default") => {
  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.Free,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const configs: Array<[AdSlotProps | null, AdSlotProps | null, AdSlotProps]> = [
    // AD2
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec1",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes:
          sectionId === sectionData.olympic.entityId
            ? [[300, 250], [300, 600], "fluid"]
            : [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes:
          sectionId === sectionData.olympic.entityId
            ? [[300, 250], [320, 480], "fluid"]
            : [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD3
    [
      sectionData.news.peopleAndCulture.entityId === sectionId
        ? null
        : {
            ...defaultAdSlotProps,
            adUnit: "d_lrec3",
            breakpoint: theme.breakpoints.up("desktop"),
            sizes:
              sectionId === sectionData.olympic.entityId
                ? [[300, 250], "fluid"]
                : [[300, 250], [300, 600], "fluid"],
          },
      sectionData.news.peopleAndCulture.entityId === sectionId
        ? null
        : {
            ...defaultAdSlotProps,
            adUnit: "m_lrec3",
            breakpoint: theme.breakpoints.only("tablet"),
            sizes:
              sectionId === sectionData.olympic.entityId
                ? [[300, 250], "fluid"]
                : [[300, 250], [320, 480], "fluid"],
          },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
    ],
    // AD4
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3a",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes:
          sectionId === sectionData.sport.entityId
            ? [[300, 250], [300, 600], "fluid"]
            : [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3a",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes:
          sectionId === sectionData.sport.entityId
            ? [[300, 250], [320, 480], "fluid"]
            : [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD5
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec2",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
    // AD6
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec4",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec4",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec4a",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD7
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec4a",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec4a",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
  ];

  return configs;
};
