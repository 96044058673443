import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontHeebo, fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { Headline } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { LatestDiscoveryWidget } from "scmp-app/components/rhs-module/latest-discovery-widget";

export const StyledLatestDiscoveryWidget = styled(LatestDiscoveryWidget)`
  ${Headline} {
    font-weight: 700;
    font-size: 18px;
    font-family: ${fontHeebo};
    line-height: 23.4px;
  }
`;

export const HighlightsFrom = styled.div`
  color: #00000080;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const Title = styled(BaseLink)`
  margin-block-end: 16px;

  color: #000000;
  font-weight: 700;
  font-size: 24px;
  font-family: ${fontRobotoCondensed};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
`;
