import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemPostMagazineSectionWidgetTwo } from "scmp-app/components/content/content-item-render/variants/post-magazine-section-widget-two";
import type { twoPostMagSectionTopWidgetArticles$key } from "scmp-app/queries/__generated__/twoPostMagSectionTopWidgetArticles.graphql";
import type { twoPostMagSectionTopWidgetSection$key } from "scmp-app/queries/__generated__/twoPostMagSectionTopWidgetSection.graphql";

import {
  Container,
  Description,
  StyledSwiper,
  StyledSwiperSlide,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: twoPostMagSectionTopWidgetArticles$key;
  sectionReference: twoPostMagSectionTopWidgetSection$key;
};
export const SectionTopWidgetTwo: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  sectionReference,
}) => {
  const section = useFragment(
    graphql`
      fragment twoPostMagSectionTopWidgetSection on Section {
        name
        description {
          text
        }
        ...entityLink
      }
    `,
    sectionReference ?? null,
  );

  const data = useFragment(
    graphql`
      fragment twoPostMagSectionTopWidgetArticles on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...postMagazineSectionWidgetTwoContent
          }
        }
      }
    `,
    reference_,
  );

  const topStories = data ?? [];
  if (topStories.length === 0) return null;

  return (
    <Container className={className}>
      <TitleContainer>
        <Title reference={section}>{section.name}</Title>
        <Description>{section.description?.text}</Description>
      </TitleContainer>

      <StyledSwiper
        cssMode={false}
        pagination={{
          clickable: true,
        }}
      >
        {data.map(({ node }, index) => (
          <StyledSwiperSlide key={index}>
            <ContentItemPostMagazineSectionWidgetTwo
              reference={node}
              topicLinkVariant={{ type: "main" }}
            />
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </Container>
  );
};

SectionTopWidgetTwo.displayName = "SectionTopWidgetTwo";
